import React from 'react';
import { Bar } from 'react-chartjs-2';

const AgeTrafficChart = ({ data }) => {
  if (!data || !data.rows) {
    return <p>Data is still not loaded.</p>;
  }

  const chartData = {
    labels: data.rows.map(row => row.dimensionValues[0].value),
    datasets: [
      {
        label: 'Active Users',
        data: data.rows.map(row => parseInt(row.metricValues[0].value, 10)),
        backgroundColor: '#82b1ff', // Customize color as needed
        borderWidth: 1,
      },
    ],
  };

  const options = {
    indexAxis: 'y', // Horizontal bar chart
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: 'Active Users by Age Group',
        font: { size: 18 },
      },
      tooltip: {
        callbacks: {
          label: (context) => `${context.raw} users`,
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Active Users',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Age Group',
        },
      },
    },
  };

  return (
    <div>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default AgeTrafficChart;
