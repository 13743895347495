import React from "react";

const TermsOfService = () => {
  return (
    <div className="terms-of-service">
      <h1>Terms of Service</h1>
      <p>
        Please read our terms of service carefully. You can view or download
        them by clicking the link below:
      </p>
      <a
        href="/terms-of-service.pdf" // Corrected path
        target="_blank"
        rel="noopener noreferrer"
      >
        View Terms of Service
      </a>
    </div>
  );
};

export default TermsOfService;
