// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.country-traffic-table-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f4f4f8;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.country-traffic-table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

.country-traffic-table th, .country-traffic-table td {
  padding: 12px;
  border-bottom: 1px solid #ddd;
}

.country-traffic-table th {
  background-color: #f0f0f0;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/styles/CountryTrafficTable.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAChB,cAAc;EACd,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,wCAAwC;AAC1C;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,6BAA6B;AAC/B;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;AACnB","sourcesContent":[".country-traffic-table-container {\r\n  width: 100%;\r\n  max-width: 800px;\r\n  margin: 0 auto;\r\n  padding: 20px;\r\n  background-color: #f4f4f8;\r\n  border-radius: 8px;\r\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\r\n}\r\n\r\n.country-traffic-table {\r\n  width: 100%;\r\n  border-collapse: collapse;\r\n  text-align: left;\r\n}\r\n\r\n.country-traffic-table th, .country-traffic-table td {\r\n  padding: 12px;\r\n  border-bottom: 1px solid #ddd;\r\n}\r\n\r\n.country-traffic-table th {\r\n  background-color: #f0f0f0;\r\n  font-weight: bold;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
