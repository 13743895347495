import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../config';
import '../styles/CreateTrends.css';

const CreateTrends = ({ onFetchDataComplete, csrfToken }) => {
  const [pastTimeOptions, setPastTimeOptions] = useState([]);
  const [sortChoicesOptions, setSortChoicesOptions] = useState([]);
  const [past_time, setTime] = useState('');
  const [country_name, setCountry] = useState('');
  const [sort_choices, setChoices] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOptions = async () => {
      const token = localStorage.getItem('access_token');

      try {
        const timeResponse = await axios.get(`${API_URL}/time-choices/`, {
          headers: {
            'X-CSRFToken': csrfToken,
            'Authorization': `Bearer ${token}`,
          },
          withCredentials: true,
        });
        setPastTimeOptions(timeResponse.data);

        const sortResponse = await axios.get(`${API_URL}/sort-choices/`, {
          headers: {
            'X-CSRFToken': csrfToken,
            'Authorization': `Bearer ${token}`,
          },
          withCredentials: true,
        });
        setSortChoicesOptions(sortResponse.data);
      } catch (error) {
        console.error('Error fetching options:', error);
        setError('Failed to fetch options. Please try again.');
      }
    };

    fetchOptions();
  }, [csrfToken]);

  const handleFetchData = async () => {
    setLoading(true);
    setError(null);

    const token = localStorage.getItem('access_token');
    console.log("Access Token:", token);
    console.log("CSRF Token:", csrfToken);

    if (!token || !csrfToken) {
      setError('Missing authentication tokens. Please log in again.');
      setLoading(false);
      return;
    }

    try {
      const payload = {
        past_time: past_time,
        country_name: country_name,
        sort_choices: sort_choices,
      };

      const trendsResponse = await axios.post(
        `${API_URL}/google-trends/`,
        payload,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      onFetchDataComplete({ trendsData: trendsResponse.data });
    } catch (error) {
      console.error('Error fetching trends analysis:', error);
      setError('Failed to fetch trends analysis. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
      <div className="create-trends-container">
        <h1>Google Trends</h1>
        {error && <p style={{color: 'red'}}>{error}</p>}
        <select value={past_time} onChange={(e) => setTime(e.target.value)}>
          <option value="">Select Past Time</option>
          {pastTimeOptions.map((option, index) => (
              <option key={index} value={option}>{option}</option>
          ))}
        </select>
        <input
            id="country-choice"
            className="country-input"
            value={country_name}
            onChange={(e) => setCountry(e.target.value)}
            placeholder="Country choice"
        />
        <select value={sort_choices} onChange={(e) => setChoices(e.target.value)}>
          <option value="">Select Sort Choice</option>
          {sortChoicesOptions.map((option, index) => (
              <option key={index} value={option}>{option}</option>
          ))}
        </select>
        <button onClick={handleFetchData} disabled={loading}>
          {loading ? 'Fetching...' : 'Fetch Google trends'}
        </button>
      </div>
  );
};

export default CreateTrends;
