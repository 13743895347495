import React from 'react';
import { Bar } from 'react-chartjs-2';


const CityAnalyticsChart = ({ cityData }) => {
  if (!cityData || !cityData.rows) {
    return <p>Data is still not loaded.</p>;
  }

  // Extract city names, new users, and returning users from data
  const labels = cityData.rows.map(row => row.dimensionValues[0].value);
  const newUsersData = cityData.rows.map(row => parseInt(row.metricValues[0].value, 10)); // New Users
  const returningUsersData = cityData.rows.map(row => parseInt(row.metricValues[2].value, 10)); // Returning Users

  // Data for New Users chart
  const newUsersChartData = {
    labels: labels,
    datasets: [
      {
        label: 'New Users',
        data: newUsersData,
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        barThickness: 15, // Adjust bar thickness for clarity
      },
    ],
  };

  // Data for Returning Users chart
  const returningUsersChartData = {
    labels: labels,
    datasets: [
      {
        label: 'Returning Users',
        data: returningUsersData,
        backgroundColor: 'rgba(153, 102, 255, 0.6)',
        barThickness: 15,
      },
    ],
  };

  const chartOptions = {
    indexAxis: 'y', // Horizontal bar chart
    scales: {
      x: {
        beginAtZero: true,
        max: 10000, // Adjust if needed; Chart.js will auto-scale otherwise
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
    },
    maintainAspectRatio: false, // Allows chart to fit within div container
    responsive: true,
  };

  return (
    <div>
      <div style={{ height: '400px', marginBottom: '20px' }}> {/* Fixed height for New Users */}
        <h4>New Users</h4>
        <Bar data={newUsersChartData} options={chartOptions} />
      </div>
      <div style={{ height: '400px' }}> {/* Fixed height for Returning Users */}
        <h4>Returning Users</h4>
        <Bar data={returningUsersChartData} options={chartOptions} />
      </div>
    </div>
  );
};

export default CityAnalyticsChart;
