import React from 'react';
import '../styles/CompetitorsKeywordsTable.css';

const CompetitorsKeywordsTable = ({ data }) => {
  const { target, location_name, limit, competitors_keywords } = data;

  return (
    <div className="competitors-keywords-table-container">
      <div className="competitors-keywords-info">
        <p><strong>Target URL:</strong> {target}</p>
        <p><strong>Location:</strong> {location_name}</p>
        <p><strong>Limit:</strong> {limit}</p>
      </div>

      <table className="competitors-keywords-table">
        <thead>
          <tr>
            <th>Keyword</th>
            <th>Search Volume</th>
            <th>Keyword Difficulty</th>
            <th>Title</th>
            <th>URL</th>
            <th>ETV</th>
          </tr>
        </thead>
        <tbody>
          {competitors_keywords.map((keyword, index) => (
            <tr key={index}>
              <td>{keyword.keyword}</td>
              <td>{keyword.search_volume}</td>
              <td>{keyword.keyword_difficulty}</td>
              <td>{keyword.title}</td>
              <td>
                <a href={keyword.url} target="_blank" rel="noopener noreferrer">
                  {keyword.url}
                </a>
              </td>
              <td>{keyword.etv.toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CompetitorsKeywordsTable;
