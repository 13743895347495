import React from 'react';
import '../styles/KeywordsIdeasTable.css';

const KeywordsIdeasTable = ({ data }) => {
  console.log("CHECK my data", data);

  // Normalize the data to always extract the `keywords` array
  const keywords =
    Array.isArray(data?.keywordsData?.keywordsData?.ideas) // Handle deeply nested format
      ? data.keywordsData.keywordsData.ideas
      : Array.isArray(data?.keywordsData?.ideas) // Handle normal format
      ? data.keywordsData.ideas
      : Array.isArray(data?.keywordsData) // Handle flat array format
      ? data.keywordsData
      : [];

  // Validate that `keywords` is an array and not empty
  if (keywords.length === 0) {
    return <p>No keyword ideas available.</p>;
  }

  return (
    <div className="keywords-ideas-table-container">
      <table className="keywords-ideas-table">
        <thead>
          <tr>
            <th>Keyword</th>
            <th>Search Volume</th>
            <th>Keyword Difficulty</th>
          </tr>
        </thead>
        <tbody>
          {keywords.map((idea, index) => (
            <tr key={index}>
              <td>{idea.keyword}</td>
              <td>{idea.search_volume}</td>
              <td>{idea.keyword_difficulty}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default KeywordsIdeasTable;
