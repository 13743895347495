import React from 'react';
import '../styles/DomainCompetitorTable.css';

const DomainCompetitorTable = ({ data }) => {
  const { target, location_name, limit, competitors_domains } = data;

  return (
    <div className="domain-competitor-table-container">
      {/* General Information */}
      <div className="general-info">
        <h3>Target Domain: {target}</h3>
        <p>Location: {location_name}</p>
        <p>Limit: {limit}</p>
      </div>

      {/* Competitors Table */}
      <table className="domain-competitor-table">
        <thead>
          <tr>
            <th>Gender Distribution</th>
            <th>Age Distribution</th>
            <th>Pos 1</th>
            <th>Pos 2-3</th>
            <th>Pos 4-10</th>
            <th>Pos 11-20</th>
            <th>Pos 21-30</th>
            <th>Pos 31-40</th>
            <th>Pos 41-50</th>
            <th>Pos 51-60</th>
            <th>Pos 61-70</th>
            <th>Pos 71-80</th>
            <th>Pos 81-90</th>
            <th>Pos 91-100</th>
            <th>Estimated Traffic Volume (ETV)</th>
          </tr>
        </thead>
        <tbody>
          {competitors_domains.map((competitor, index) => (
            <tr key={index}>
              {/* Gender Distribution */}
              <td>
                Female: {competitor.clickstream_gender_distribution.female}%, Male: {competitor.clickstream_gender_distribution.male}%
              </td>

              {/* Age Distribution */}
              <td>
                {Object.entries(competitor.clickstream_age_distribution).map(([ageGroup, percentage]) => (
                  <div key={ageGroup}>
                    {ageGroup}: {percentage}%
                  </div>
                ))}
              </td>

              {/* Position Columns */}
              <td>{competitor.pos_1}</td>
              <td>{competitor.pos_2_3}</td>
              <td>{competitor.pos_4_10}</td>
              <td>{competitor.pos_11_20}</td>
              <td>{competitor.pos_21_30}</td>
              <td>{competitor.pos_31_40}</td>
              <td>{competitor.pos_41_50}</td>
              <td>{competitor.pos_51_60}</td>
              <td>{competitor.pos_61_70}</td>
              <td>{competitor.pos_71_80}</td>
              <td>{competitor.pos_81_90}</td>
              <td>{competitor.pos_91_100}</td>

              {/* Estimated Traffic Volume */}
              <td>{competitor.etv.toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DomainCompetitorTable;
