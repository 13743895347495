// components/CountryAnalyticsChart.js
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, Tooltip, Legend, LinearScale, CategoryScale, BarElement } from 'chart.js';

ChartJS.register(Tooltip, Legend, LinearScale, CategoryScale, BarElement);

const CountryAnalyticsChart = ({ countryData }) => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    if (countryData) {
      const countries = countryData.rows.map(row => row.dimensionValues[0].value);
      const activeUsers = countryData.rows.map(row => parseInt(row.metricValues[0].value, 10));

      setChartData({
        labels: countries,
        datasets: [
          {
            label: 'Active Users',
            data: activeUsers,
            backgroundColor: 'rgba(75, 192, 192, 0.6)',
          },
        ],
      });
    }
  }, [countryData]);

  return (
    <div>
      {chartData ? (
        <Bar data={chartData} options={{ responsive: true, indexAxis: 'y' }} />
      ) : (
        <p>Data is still not loaded.</p>
      )}
    </div>
  );
};

export default CountryAnalyticsChart;
