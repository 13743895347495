import React from 'react';
import { Bar } from 'react-chartjs-2';

const SourceMediumSessionsChart = ({ sourceMediumData }) => {
  if (!sourceMediumData || !sourceMediumData.rows) {
    return <p>Data is still not loaded.</p>;
  }

  // Data for the Source/Medium Sessions chart
  const chartData = {
    labels: sourceMediumData.rows.map(row => row.dimensionValues[0].value),
    datasets: [
      {
        label: 'Sessions',
        data: sourceMediumData.rows.map(row => parseInt(row.metricValues[0].value, 10)),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
    ],
  };

  return (
    <div>
      <h3>Sessions by Source/Medium</h3>
      <Bar
        data={chartData}
        options={{
          indexAxis: 'y', // Horizontal bar chart
          scales: {
            x: {
              beginAtZero: true,
            },
          },
          responsive: true,
          plugins: {
            legend: {
              display: true,
            },
          },
        }}
      />
    </div>
  );
};

export default SourceMediumSessionsChart;
