import React from 'react';
import { Bar } from 'react-chartjs-2';

const EngagedSessionsChart = ({ sessionData }) => {
  if (!sessionData || !sessionData.rows) {
    return <p>Data is still not loaded.</p>;
  }

  // Data for Engaged Sessions chart
  const engagedSessionsData = {
    labels: sessionData.rows.map(row => row.dimensionValues[0].value),
    datasets: [
      {
        label: 'Engaged Sessions',
        data: sessionData.rows.map(row => parseInt(row.metricValues[0].value, 10)),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
    ],
  };

  // Data for Average Engagement per Session chart (converting seconds to minutes for readability)
  const avgEngagementData = {
    labels: sessionData.rows.map(row => row.dimensionValues[0].value),
    datasets: [
      {
        label: 'Avg Engagement Time per Session (seconds)',
        data: sessionData.rows.map(row => parseFloat(row.metricValues[2].value) * 60), // Assuming it's in minutes, multiply by 60 for seconds
        backgroundColor: 'rgba(153, 102, 255, 0.6)',
      },
    ],
  };

  return (
    <div>
      <Bar
        data={engagedSessionsData}
        options={{
          indexAxis: 'y', // Horizontal bar chart
          scales: {
            x: {
              beginAtZero: true,
            },
          },
          responsive: true,
          plugins: {
            legend: {
              display: true,
            },
          },
        }}
      />

      <h3>Average Engagement Time per Session by Page Title</h3>
      <Bar
        data={avgEngagementData}
        options={{
          indexAxis: 'y', // Horizontal bar chart
          scales: {
            x: {
              beginAtZero: true,
              ticks: {
                callback: value => `${value} sec`, // Add 'sec' to x-axis labels
              },
            },
          },
          responsive: true,
          plugins: {
            legend: {
              display: true,
            },
          },
        }}
      />
    </div>
  );
};

export default EngagedSessionsChart;
