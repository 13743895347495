import React from 'react';
import '../styles/PagesTrafficTable.css';

const PagesTrafficTable = ({ data }) => {
  return (
    <div className="pages-traffic-table-container">
      <table className="pages-traffic-table">
        <thead>
          <tr>
            <th>Page</th>
            <th>Clicks</th>
            <th>Impressions</th>
            <th>CTR</th>
            <th>Position</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td className="page-link">
                <a href={row.page} target="_blank" rel="noopener noreferrer">
                  {row.page}
                </a>
              </td>
              <td>{row.clicks}</td>
              <td>{row.impressions}</td>
              <td>{row.ctr}</td>
              <td>{row.position.toFixed(1)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PagesTrafficTable;
