import React, { useState, useEffect } from "react";
import axios from "axios";
import "../styles/Signup.css";
import { API_URL } from "../config";
import { useNavigate } from "react-router-dom";

// Component for Personal Information
function PersonalInfo({ formData, handleChange, handleNext }) {
  return (
    <div>
      <h2>Personal Info</h2>
      <div className="input-group-row">
        <input
          type="text"
          name="first_name"
          className="signup-input"
          placeholder="First Name"
          onChange={handleChange}
          value={formData.first_name}
          required
        />
        <input
          type="text"
          name="last_name"
          className="signup-input"
          placeholder="Last Name"
          onChange={handleChange}
          value={formData.last_name}
          required
        />
      </div>
      <div className="input-group-row">
        <input
          type="email"
          name="email"
          className="signup-input"
          placeholder="Email"
          onChange={handleChange}
          value={formData.email}
          required
        />
        <input
          type="password"
          name="password"
          className="signup-input"
          placeholder="Password"
          onChange={handleChange}
          value={formData.password}
          required
        />
      </div>
      <div className="input-group-row">
        <input
          type="password"
          name="confirmPassword"
          className="signup-input"
          placeholder="Confirm Password"
          onChange={handleChange}
          value={formData.confirmPassword}
          required
        />
        <select
          name="job_level"
          className="signup-input"
          onChange={handleChange}
          value={formData.job_level}
        >
          <option value="">Select Job Level</option>
          <option value="C-Level">C-Level</option>
          <option value="VP or Executive">VP or Executive</option>
          <option value="Director">Director</option>
          <option value="Team Leader">Team Leader</option>
          <option value="Manager">Manager</option>
          <option value="Senior">Senior</option>
          <option value="Junior">Junior</option>
          <option value="Owner/Founder">Owner/Founder</option>
          <option value="Freelancer">Freelancer</option>
          <option value="Other">Other</option>
        </select>
      </div>
      <div className="input-group-row single">
        <select
          name="role"
          className="signup-input"
          onChange={handleChange}
          value={formData.role}
        >
          <option value="">Select Role</option>
          <option value="Marketing">Marketing</option>
          <option value="Design">Design</option>
          <option value="Engineering">Engineering</option>
          <option value="Finance">Finance</option>
          <option value="Product">Product</option>
          <option value="Sales">Sales</option>
          <option value="Customer Support">Customer Support</option>
          <option value="Customer Success">Customer Success</option>
          <option value="HR">HR</option>
          <option value="Project Management">Project Management</option>
          <option value="Other">Other</option>
        </select>
      </div>
      <div style={{ textAlign: "center" }}>
        <button className="next-button" onClick={handleNext}>
          Next
        </button>
      </div>
    </div>
  );
}


// Component for Company Information
function CompanyInfo({ formData, handleChange, handleNext, handlePrevious }) {
  return (
      <div>
          <h2>Company Info</h2>
          <div className="input-group-row">
              <select
                  name="company_industry"
                  className="signup-input"
                  onChange={handleChange}
                  value={formData.company_industry}
              >
                  <option value="">Select Industry</option>
                  <option value="Technology">Technology</option>
                  <option value="Finance">Finance</option>
              </select>
              <select
                  name="company_size"
                  className="signup-input"
                  onChange={handleChange}
                  value={formData.company_size}
              >
                  <option value="">Select Size</option>
                  <option value="Small">Small</option>
                  <option value="Medium">Medium</option>
                  <option value="Large">Large</option>
              </select>
          </div>
          <div className="input-group-row">
              <input
                  type="text"
                  name="company_name"
                  className="signup-input"
                  placeholder="Workspace name"
                  onChange={handleChange}
                  value={formData.company_name}
              />
              <input
                  type="text"
                  name="company_url"
                  className="signup-input"
                  placeholder="Company URL"
                  onChange={handleChange}
                  value={formData.company_url}
              />
          </div>

                    <div className="button-group">
              <button className="previous-button" onClick={handlePrevious}>
                  Previous
              </button>
              <button className="next-button" onClick={handleNext}>
                  Next
              </button>
                    </div>
      </div>
  );
}

// Component for Brand Information
function BrandInfo({formData, handleChange, handleNext, handlePrevious}) {
    return (
        <div>
            <h2>Brand Info</h2>
            <div className="input-group">
                <input
                    type="text"
                    name="brand_voice_name"
                    className="signup-input"
                    placeholder="Brand Voice Name"
                    onChange={handleChange}
                    value={formData.brand_voice_name}
                />
            </div>
            <div className="input-group">
                <input
                    type="text"
                    name="brand_personality"
                    className="signup-input"
                    placeholder="Brand Personality"
                    onChange={handleChange}
                    value={formData.brand_personality}
                />
            </div>
            <div className="input-group">
        <textarea
            name="brand_mission"
            className="signup-input"
            placeholder="Brand Mission"
            onChange={handleChange}
            value={formData.brand_mission}
        />
            </div>
                  <div className="button-group">
            <button className="previous-button" onClick={handlePrevious}>
                Previous
            </button>
            <button className="next-button" onClick={handleNext}>
                Next
            </button>
                  </div>
        </div>
    );
}

// Component for Target Audience
function TargetAudience({ formData, handleChange, handleSubmit, handlePrevious }) {
  return (
    <div>
      <h2>Target Audience</h2>
      <div className="input-group-row">
        <input
          type="text"
          name="target_audience_name"
          className="signup-input"
          placeholder="Audience Name"
          onChange={handleChange}
          value={formData.target_audience_name}
        />
        <input
          type="text"
          name="target_audience_gender"
          className="signup-input"
          placeholder="Audience Gender"
          onChange={handleChange}
          value={formData.target_audience_gender}
        />
      </div>
      <div className="input-group-row">
        <input
          type="number"
          name="target_audience_age_min"
          className="signup-input"
          placeholder="Min Age"
          onChange={handleChange}
          value={formData.target_audience_age_min}
        />
        <input
          type="number"
          name="target_audience_age_max"
          className="signup-input"
          placeholder="Max Age"
          onChange={handleChange}
          value={formData.target_audience_age_max}
        />
      </div>
      <div className="button-group">
        <button className="previous-button" onClick={handlePrevious}>
          Previous
        </button>
        <button className="submit-button" onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </div>
  );
}


// Main Signup Component
function Signup() {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    first_name: "",
    last_name: "",
    job_level: "",
    role: "",
    company_name: "",
    company_url: "",
    company_industry: "",
    company_size: "",
    brand_voice_name: "",
    brand_personality: "",
    brand_mission: "",
    target_audience_name: "",
    target_audience_gender: "",
    target_audience_age_min: "",
    target_audience_age_max: "",
  });

  const [csrfToken, setCsrfToken] = useState("");
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        const response = await axios.get(`${API_URL}/csrf-token/`);
        setCsrfToken(response.data.csrfToken);
        console.log("Fetched CSRF token:", response.data.csrfToken);
      } catch (error) {
        console.error("Failed to fetch CSRF token:", error);
      }
    };
    fetchCsrfToken();
  }, []);

  const handleNext = () => setStep(step + 1);
  const handlePrevious = () => setStep(step - 1);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

const handleSubmit = async () => {
  if (formData.password !== formData.confirmPassword) {
    return;
  }

  try {
    // Create a FormData object to send as multipart/form-data
    const formDataFormatted = new FormData();
    Object.keys(formData).forEach((key) => {
      if (formData[key]) {
        formDataFormatted.append(key, formData[key]);
      }
    });

    const response = await axios.post(`${API_URL}/users/signup/`, formDataFormatted, {
      headers: {
        "Content-Type": "multipart/form-data",
        "X-CSRFToken": csrfToken,
      },
    });

    console.log("Signup onboarding successful:", response.data);
    alert("Signup onboarding completed successfully!");
    navigate("/login");
  } catch (error) {
    console.error("Signup onboarding failed:", error.response ? error.response.data : error.message);
    alert("Signup onboarding failed. Please try again.");
  }
};


  return (
      <div className="signup-container">
          <div className="signup-header">
              <div className="signup-header-text">
                  <h1>Let’s personalize the magic to your business and audience</h1>
                  <p>Your info helps us deliver tailored insights and recommendations</p>
              </div>
              <img
                  src={require("../assets/onboarding_content.png")}
                  alt="Magic Wand"
                  className="onboarding-image"
              />
          </div>
          <div className="signup-form">
              {step === 1 && <PersonalInfo formData={formData} handleChange={handleChange} handleNext={handleNext}/>}
              {step === 2 && (
                  <CompanyInfo
                      formData={formData}
                      handleChange={handleChange}
                      handleNext={handleNext}
                      handlePrevious={handlePrevious}
                  />
              )}
              {step === 3 && (
                  <BrandInfo
                      formData={formData}
                      handleChange={handleChange}
                      handleNext={handleNext}
                      handlePrevious={handlePrevious}
                  />
              )}
              {step === 4 && (
                  <TargetAudience
                      formData={formData}
                      handleChange={handleChange}
                      handleSubmit={handleSubmit}
                      handlePrevious={handlePrevious}
                  />
              )}
          </div>
          <div className="pagination-dots">
              {[1, 2, 3, 4].map((page) => (
                  <span
                      key={page}
                      className={page === step ? "active" : ""}
                  ></span>
              ))}
          </div>
      </div>

  );
}

export default Signup;
