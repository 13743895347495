// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Container styling for the CompetitorsKeywords component */
.competitors-keywords-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f4f4f8;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Header styling */
.competitors-keywords-container h1 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

/* Error message styling */
.error-message {
  color: red;
  font-weight: bold;
  margin-bottom: 15px;
}

/* Styling for input fields */
.input-field {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  box-sizing: border-box;
  height: 40px;
}

/* Styling for the button */
.fetch-button {
  width: 100%;
  padding: 10px;
  background-color: #553c9a;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.fetch-button:hover {
  background-color: #6a4eb0;
}
`, "",{"version":3,"sources":["webpack://./src/styles/CompetitorsKeywords.css"],"names":[],"mappings":"AAAA,4DAA4D;AAC5D;EACE,gBAAgB;EAChB,cAAc;EACd,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,yCAAyC;EACzC,kBAAkB;AACpB;;AAEA,mBAAmB;AACnB;EACE,eAAe;EACf,WAAW;EACX,mBAAmB;AACrB;;AAEA,0BAA0B;AAC1B;EACE,UAAU;EACV,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA,6BAA6B;AAC7B;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;EACf,sBAAsB;EACtB,YAAY;AACd;;AAEA,2BAA2B;AAC3B;EACE,WAAW;EACX,aAAa;EACb,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["/* Container styling for the CompetitorsKeywords component */\r\n.competitors-keywords-container {\r\n  max-width: 500px;\r\n  margin: 0 auto;\r\n  padding: 20px;\r\n  background-color: #f4f4f8;\r\n  border-radius: 8px;\r\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\r\n  text-align: center;\r\n}\r\n\r\n/* Header styling */\r\n.competitors-keywords-container h1 {\r\n  font-size: 24px;\r\n  color: #333;\r\n  margin-bottom: 20px;\r\n}\r\n\r\n/* Error message styling */\r\n.error-message {\r\n  color: red;\r\n  font-weight: bold;\r\n  margin-bottom: 15px;\r\n}\r\n\r\n/* Styling for input fields */\r\n.input-field {\r\n  width: 100%;\r\n  padding: 10px;\r\n  margin-bottom: 15px;\r\n  border: 1px solid #ccc;\r\n  border-radius: 5px;\r\n  font-size: 14px;\r\n  box-sizing: border-box;\r\n  height: 40px;\r\n}\r\n\r\n/* Styling for the button */\r\n.fetch-button {\r\n  width: 100%;\r\n  padding: 10px;\r\n  background-color: #553c9a;\r\n  color: white;\r\n  border: none;\r\n  border-radius: 5px;\r\n  cursor: pointer;\r\n  font-size: 16px;\r\n  transition: background-color 0.3s ease;\r\n}\r\n\r\n.fetch-button:hover {\r\n  background-color: #6a4eb0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
