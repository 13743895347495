import React, { useState, useEffect, useCallback  } from "react";
import axios from "axios";
import "../styles/Signup.css";
import { API_URL } from "../config";
import { useNavigate } from "react-router-dom";
import ReactSlider from "react-slider";

// Component for Personal Information
function PersonalInfo({ formData, handleChange, handleNext }) {
  return (
    <div>
      <h2>Personal Info</h2>
        <div className="input-group-row">
            <div>
                <label htmlFor="first_name">First Name</label>
                <input
                    type="text"
                    name="first_name"
                    className="signup-input"
                    placeholder="Enter your first name"
                    onChange={handleChange}
                    value={formData.first_name}
                    required
                />
            </div>
        <div>
            <label htmlFor="first_name">Last Name</label>
            <input
                type="text"
                name="last_name"
                className="signup-input"
                placeholder="Enter your last name"
                onChange={handleChange}
                value={formData.last_name}
                required
            />
        </div>
        </div>
        <div className="input-group-row">
            <div>
            <label htmlFor="email">Email</label>
            <input
                type="email"
                name="email"
                className="signup-input"
                placeholder="Enter your email address"
                onChange={handleChange}
                value={formData.email}
                required
            />
            </div>
        </div>
        <div className="input-group-row">
            <div>
                <label htmlFor="email">Password</label>
                <input
                    type="password"
                    name="password"
                    className="signup-input"
                    placeholder="Enter your password"
                    onChange={handleChange}
                    value={formData.password}
                    required
                />
            </div>
            <div>
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
                type="password"
                name="confirmPassword"
                className="signup-input"
                placeholder="Confirm your password"
                onChange={handleChange}
                value={formData.confirmPassword}
                required
            />
            </div>
        </div>
        <div className="input-group-row">
            <div>
                <label htmlFor="job_level">Select Job level</label>
                <select
                    name="job_level"
                    className="signup-input"
                    onChange={handleChange}
                    value={formData.job_level}
                >
                    <option value="">Select your professional level</option>
                    <option value="C-Level">C-Level</option>
                    <option value="VP or Executive">VP or Executive</option>
                    <option value="Director">Director</option>
                    <option value="Team Leader">Team Leader</option>
                    <option value="Manager">Manager</option>
                    <option value="Senior">Senior</option>
                    <option value="Junior">Junior</option>
                    <option value="Owner/Founder">Owner/Founder</option>
                    <option value="Freelancer">Freelancer</option>
                    <option value="Other">Other</option>
                </select>
            </div>
            <div>
            <label htmlFor="role">Select Role</label>
            <select
                name="role"
                className="signup-input"
                onChange={handleChange}
                value={formData.role}
            >
                <option value="">Select your job role</option>
                <option value="Marketing">Marketing</option>
                <option value="Design">Design</option>
                <option value="Engineering">Engineering</option>
                <option value="Finance">Finance</option>
                <option value="Product">Product</option>
                <option value="Sales">Sales</option>
                <option value="Customer Support">Customer Support</option>
                <option value="Customer Success">Customer Success</option>
                <option value="HR">HR</option>
                <option value="Project Management">Project Management</option>
                <option value="Other">Other</option>
            </select>
            </div>
        </div>
        <div style={{textAlign: "center"}}>
        <button className="next-button" onClick={handleNext}>
          Next
        </button>
      </div>
    </div>
  );
}


// Component for Company Information
function CompanyInfo({ formData, handleChange, handleNext, handlePrevious }) {
  return (
      <div>
          <h2>Company Info</h2>
          <div className="input-group-row">
              <div>
                  <label htmlFor="company_industry">Select Industry</label>
                  <select
                      name="company_industry"
                      className="signup-input"
                      onChange={handleChange}
                      value={formData.company_industry}
                  >
                      <option value="">Select your business sector</option>
                      <option value="Technology">Technology</option>
                      <option value="Finance">Finance</option>
                      <option value="Healthcare">Healthcare</option>
                      <option value="Retail">Retail</option>
                      <option value="Education">Education</option>
                      <option value="Manufacturing">Manufacturing</option>
                      <option value="Real Estate">Real Estate</option>
                      <option value="Transportation">Transportation</option>
                      <option value="Energy">Energy</option>
                      <option value="Entertainment">Entertainment</option>
                      <option value="Hospitality">Hospitality</option>
                      <option value="Construction">Construction</option>
                      <option value="Legal">Legal</option>
                      <option value="Agriculture">Agriculture</option>
                      <option value="Telecommunications">Telecommunications</option>
                      <option value="Automotive">Automotive</option>
                      <option value="Food & Beverage">Food & Beverage</option>
                      <option value="Aerospace">Aerospace</option>
                      <option value="Public Sector">Public Sector</option>
                      <option value="Nonprofit">Nonprofit</option>
                      <option value="Media">Media</option>
                      <option value="Pharmaceuticals">Pharmaceuticals</option>
                      <option value="Insurance">Insurance</option>
                      <option value="Sports">Sports</option>
                      <option value="Consulting">Consulting</option>
                  </select>
              </div>
              <div>
              <label htmlFor="company_size">Select Size</label>
              <select
                  name="company_size"
                  className="signup-input"
                  onChange={handleChange}
                  value={formData.company_size}
              >
                  <option value="">Select organization size</option>
                  <option value="Small">Small</option>
                  <option value="Medium">Medium</option>
                  <option value="Large">Large</option>
              </select>
              </div>
          </div>
          <div className="input-group-row">
              <div>
                  <label htmlFor="company_name">Workspace name</label>
                  <input
                      type="text"
                      name="company_name"
                      className="signup-input"
                      placeholder="Add your team or workspace name"
                      onChange={handleChange}
                      value={formData.company_name}
                  />
              </div>
              <div>
              <label htmlFor="company_url">Company URL</label>
              <input
                  type="text"
                  name="company_url"
                  className="signup-input"
                  placeholder="Enter your company website URL"
                  onChange={handleChange}
                  value={formData.company_url}
              />
              </div>
          </div>

          <div className="button-group">
          <button className="previous-button" onClick={handlePrevious}>
                  Previous
              </button>
              <button className="next-button" onClick={handleNext}>
                  Next
              </button>
                    </div>
      </div>
  );
}

// Component for Brand Information
function BrandInfo({ formData, handleChange, handleNext, handlePrevious }) {
  const [selectedPersonalities, setSelectedPersonalities] = useState([]);

  const personalityOptions = [
    "Innovative",
    "Trustworthy",
    "Friendly",
    "Professional",
    "Bold",
    "Sophisticated",
    "Authentic",
    "Fun",
    "Caring",
    "Inspirational",
    "Competent",
    "Leader",
    "Empowering",
  ];

  const togglePersonality = (personality) => {
    if (selectedPersonalities.includes(personality)) {
      // Remove personality if already selected
      setSelectedPersonalities((prev) =>
        prev.filter((item) => item !== personality)
      );
    } else if (selectedPersonalities.length < 3) {
      // Add personality if less than 3 selected
      setSelectedPersonalities((prev) => [...prev, personality]);
    }
  };

useEffect(() => {
  // Update formData whenever selectedPersonalities changes
  handleChange({
    target: {
      name: "brand_personality",
      value: selectedPersonalities.join(", "),
    },
  });
}, [selectedPersonalities, handleChange]); // Include handleChange in dependencies


  return (
    <div>
      <h2>Brand Info</h2>
        <div className="input-group">
            <div>
            <label htmlFor="brand_voice_name">Brand Voice Name</label>
            <input
                type="text"
                name="brand_voice_name"
                className="signup-input"
                placeholder="Define your brand’s unique voice"
                onChange={handleChange}
                value={formData.brand_voice_name}
            />
            </div>
        </div>
        <div className="personality-options-container">
        <h3>Select up to 3 Brand Personalities:</h3>
        <div className="personality-options">
          {personalityOptions.map((option) => (
            <div
              key={option}
              className={`personality-option ${
                selectedPersonalities.includes(option) ? "selected" : ""
              }`}
              onClick={() => togglePersonality(option)}
            >
              {option}
            </div>
          ))}
        </div>
      </div>
        <div className="input-group">
            <div>
            <label htmlFor="brand_mission">Brand Voice Name</label>
            <textarea
                name="brand_mission"
                className="signup-input"
                placeholder="Summarize your brand’s purpose and goals"
                onChange={handleChange}
                value={formData.brand_mission}
            />
            </div>
        </div>
        <div className="button-group">
            <button className="previous-button" onClick={handlePrevious}>
          Previous
        </button>
        <button className="next-button" onClick={handleNext}>
          Next
        </button>
      </div>
    </div>
  );
}

// Component for Target Audience

// Component for Target Audience
function TargetAudience({
  formData,
  setFormData,
  handleSubmit,
  handlePrevious,
}) {
  const [interests, setInterests] = useState([]);
  const [painPoints, setPainPoints] = useState([]);
  const [newInterest, setNewInterest] = useState(""); // To handle input for new interests
  const [newPainPoint, setNewPainPoint] = useState(""); // To handle input for new pain points
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchPersonaData = async () => {
      setLoading(true);
      try {
        if (!formData.company_url || formData.company_url.trim() === "") {
          throw new Error("Company URL is required");
        }

        const interestsResponse = await axios.post(
          `${API_URL}/marketing_research/api/persona-interest/`,
          { company_url: formData.company_url }
        );
        const interestsData = Array.isArray(interestsResponse.data.options)
          ? interestsResponse.data.options.map((item) => item.explanation)
          : [];
        setInterests(interestsData);

        const painPointsResponse = await axios.post(
          `${API_URL}/marketing_research/api/persona-pain/`,
          { company_url: formData.company_url }
        );
        const painPointsData = Array.isArray(painPointsResponse.data.options)
          ? painPointsResponse.data.options.map((item) => item.explanation)
          : [];
        setPainPoints(painPointsData);
      } catch (error) {
        console.error("Error fetching persona data:", error);
        alert("Failed to fetch persona data. Please ensure the company URL is valid.");
      } finally {
        setLoading(false);
      }
    };

    if (formData.company_url) {
      fetchPersonaData();
    }
  }, [formData.company_url]);

  const addNewInterest = () => {
    if (newInterest.trim() !== "") {
      setInterests((prev) => [...prev, newInterest]);
      setNewInterest(""); // Reset input
    }
  };

  const addNewPainPoint = () => {
    if (newPainPoint.trim() !== "") {
      setPainPoints((prev) => [...prev, newPainPoint]);
      setNewPainPoint(""); // Reset input
    }
  };

  const removeInterest = (interest) => {
    setInterests((prev) => prev.filter((item) => item !== interest));
  };

  const removePainPoint = (painPoint) => {
    setPainPoints((prev) => prev.filter((item) => item !== painPoint));
  };

  const handleFinalSubmit = () => {
    const updatedFormData = {
      ...formData,
      target_audience_interests: interests.join(", "),
      target_audience_pain_points: painPoints.join(", "),
    };

    setFormData(updatedFormData);
    handleSubmit(updatedFormData); // Pass updated data to submit
  };

  if (loading) {
    return (
      <div className="spinner-container">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div>
      <h2>Target Audience</h2>
        <div className="input-group-row">
            <div>
                <label htmlFor="target_audience_name">Audience Name</label>
                <input
                    type="text"
                    name="target_audience_name"
                    className="signup-input"
                    placeholder="Define your audience segment"
                    onChange={(e) =>
                        setFormData((prevFormData) => ({
                            ...prevFormData,
                            target_audience_name: e.target.value,
                        }))
                    }
                    value={formData.target_audience_name || ""}
                />
            </div>
            <div>
            <label htmlFor="target_audience_gender">Audience Name</label>
            <select
                name="target_audience_gender"
                className="signup-input"
                onChange={(e) =>
                    setFormData((prevFormData) => ({
                        ...prevFormData,
                        target_audience_gender: e.target.value,
                    }))
                }
                value={formData.target_audience_gender || ""}
            >
                <option value="">Choose gender preference</option>
                <option value="Female">Female</option>
                <option value="Male">Male</option>
                <option value="NotSpecified">Don't want to specify</option>
            </select>
            </div>
        </div>

        <div className="age-range-slider">
            <p>Target audience age?</p>
        <ReactSlider
          className="custom-slider"
          thumbClassName="custom-slider-thumb"
          trackClassName="custom-slider-track"
          value={[
            formData.target_audience_age_min || 10,
            formData.target_audience_age_max || 65,
          ]}
          min={10}
          max={65}
          step={1}
          onChange={(values) =>
            setFormData((prevFormData) => ({
              ...prevFormData,
              target_audience_age_min: values[0],
              target_audience_age_max: values[1],
            }))
          }
        />
        <div className="slider-labels">
          <span>Min: {formData.target_audience_age_min}</span>
          <span>Max: {formData.target_audience_age_max}</span>
        </div>
      </div>

      <div>
        <h3>Persona's Interests</h3>
        <div className="options-container">
          {interests.map((interest, index) => (
            <div key={index} className="option-item">
              {interest}
              <button
                className="remove-button"
                onClick={() => removeInterest(interest)}
              >
                ✖
              </button>
            </div>
          ))}
        </div>
        <div className="input-group-row">
          <input
            type="text"
            className="signup-input"
            placeholder="Add new interest"
            value={newInterest}
            onChange={(e) => setNewInterest(e.target.value)}
          />
          <button className="add-button" onClick={addNewInterest}>
            Add
          </button>
        </div>
      </div>

      <div>
        <h3>Persona's Pain Points</h3>
        <div className="options-container">
          {painPoints.map((painPoint, index) => (
            <div key={index} className="option-item">
              {painPoint}
              <button
                className="remove-button"
                onClick={() => removePainPoint(painPoint)}
              >
                ✖
              </button>
            </div>
          ))}
        </div>
        <div className="input-group-row">
          <input
            type="text"
            className="signup-input"
            placeholder="Add new pain point"
            value={newPainPoint}
            onChange={(e) => setNewPainPoint(e.target.value)}
          />
          <button className="add-button" onClick={addNewPainPoint}>
            Add
          </button>
        </div>
      </div>

      <div className="button-group">
        <button className="previous-button" onClick={handlePrevious}>
          Previous
        </button>
        <button className="submit-button" onClick={handleFinalSubmit}>
          Submit
        </button>
      </div>
    </div>
  );
}






function ProgressBar({step}) {
    const steps = ["Personal", "Company", "Brand", "Target audience"];
    return (
        <div className="progress-bar">
            {steps.map((label, index) => (
                <div
                    key={index}
                    className={`progress-step ${step === index + 1 ? "active" : ""}`}
                >
                    <span className="progress-index">{index + 1}</span>
                    <span className="progress-label">{label}</span>
                </div>
            ))}
        </div>
    );
}

// Main Signup Component
function Signup() {
    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(false); // Add loading state
    const [formData, setFormData] = useState({
        email: "",
        password: "",
        confirmPassword: "",
        first_name: "",
        last_name: "",
        job_level: "",
        role: "",
        company_name: "",
        company_url: "",
        company_industry: "",
        company_size: "",
        brand_voice_name: "",
        brand_personality: "",
        brand_mission: "",
        target_audience_name: "",
        target_audience_gender: "",
        target_audience_age_min: "",
        target_audience_age_max: "",
    });

    const [csrfToken, setCsrfToken] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        const response = await axios.get(`${API_URL}/csrf-token/`);
        setCsrfToken(response.data.csrfToken);
        console.log("Fetched CSRF token:", response.data.csrfToken);
      } catch (error) {
        console.error("Failed to fetch CSRF token:", error);
      }
    };
    fetchCsrfToken();
  }, []);

  const handleNext = async () => {
    if (step === 2) {
      // Call API for Brand Mission when transitioning from Step 2 to Step 3
      const companyUrl = formData.company_url;
      if (companyUrl) {
        setLoading(true); // Show spinner
        try {
          const response = await axios.post(`${API_URL}/marketing_research/api/brand-mission/`, {
            company_url: companyUrl,
          });
          // Set API response as the default Brand Mission
          setFormData((prevFormData) => ({
            ...prevFormData,
            brand_mission: response.data, // Update this based on API response structure
          }));
        } catch (error) {
          console.error("Failed to fetch brand mission:", error);
          alert("Failed to fetch Brand Mission. Please check the Company URL or try again later.");
        } finally {
          setLoading(false); // Hide spinner
        }
      }
    }
    setStep(step + 1); // Proceed to the next step
  };

  const handlePrevious = () => setStep(step - 1);

  const handleChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    },
    [setFormData]
  );

const handleSubmit = async (finalFormData) => {
  console.log("Final formData being submitted:", finalFormData); // Log data being submitted

  if (finalFormData.password !== finalFormData.confirmPassword) {
    console.log("Password mismatch!"); // Log password mismatch
    alert("Passwords do not match!");
    return;
  }

  try {
    const formDataFormatted = new FormData();
    Object.keys(finalFormData).forEach((key) => {
      if (finalFormData[key]) {
        formDataFormatted.append(key, finalFormData[key]);
      }
    });

    console.log("Formatted formData for submission:", formDataFormatted); // Log FormData object

    const response = await axios.post(
      `${API_URL}/users/signup/`,
      formDataFormatted,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRFToken": csrfToken,
        },
      }
    );

    console.log("Signup onboarding successful:", response.data); // Log success response
    alert("Signup onboarding completed successfully!");
    navigate("/login");
  } catch (error) {
    console.error(
      "Signup onboarding failed:",
      error.response ? error.response.data : error.message
    );
    alert("Signup onboarding failed. Please try again.");
  }
};


 return (
  <div className="signup-container">
    <div className="signup-header">
      <div className="signup-header-text">
        <h1>Let’s personalize the magic to your business and audience</h1>
        <p>Your info helps us deliver tailored insights and recommendations</p>
      </div>
      <img
        src={require("../assets/onboarding_content.png")}
        alt="Magic Wand"
        className="onboarding-image"
      />
    </div>

    <ProgressBar step={step} />
    {loading ? (
      <div className="spinner-container">
        <div className="spinner"></div>
      </div>
    ) : (
      <>
        <div className="signup-form">
          {step === 1 && (
            <PersonalInfo
              formData={formData}
              handleChange={handleChange}
              handleNext={handleNext}
            />
          )}
          {step === 2 && (
            <CompanyInfo
              formData={formData}
              handleChange={handleChange}
              handleNext={handleNext}
              handlePrevious={handlePrevious}
            />
          )}
          {step === 3 && (
            <BrandInfo
              formData={formData}
              handleChange={handleChange}
              handleNext={handleNext}
              handlePrevious={handlePrevious}
            />
          )}
          {step === 4 && (
            <TargetAudience
              formData={formData}
              setFormData={setFormData} // Pass setFormData as a prop
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              handlePrevious={handlePrevious}
            />

          )}
        </div>
        <div className="pagination-dots">
          {[1, 2, 3, 4].map((page) => (
            <span
              key={page}
              className={page === step ? "active" : ""}
            ></span>
          ))}
        </div>
      </>
    )}
  </div>
);

}

export default Signup;
