import React, { useState, useEffect } from 'react';
import './App.css';
import Sidebar from './components/Sidebar';
import CreateAd from './components/CreateAd';
import CreateSocial from './components/CreateSocial';
import CreateEmail from './components/CreateEmail';
import CreatePR from './components/CreatePR';
import CreateGeneral from './components/CreateGeneral';
import CreateEcommerce from './components/CreateEcommerce';
import CreateRefine from './components/CreateRefine';
import CreateBoost from './components/CreateBoost';
import CreateTranslate from './components/CreateTranslate';
import CreateImage from './components/CreateImage';
import CreateImageAI from './components/CreateImageAI';
import CreateAnalytics from './components/CreateAnalytics';
import CountryAnalyticsChart from './components/CountryAnalyticsChart';
import CityAnalyticsChart from './components/CityAnalyticsChart';
import LandingPageSessionsChart from './components/LandingPageSessionsChart';
import LandingPageBounceChart from './components/LandingPageBounceChart';
import EngagedSessionsChart from './components/EngagedSessionsChart';
import SourceMediumSessionsChart from "./components/SourceMediumSessionsChart";
import ChannelGroupEngagedSessionsChart from "./components/ChannelGroupEngagedSessionsChart"
import GenderTrafficChart from "./components/GenderTrafficChart"
import AgeTrafficChart from "./components/AgeTrafficChart";
import InterestTrafficChart from "./components/InterestTrafficChart"
import CreateSearch from './components/CreateSearch';
import KeywordsIdeas  from './components/KeywordsIdeas';
import KeywordsSuggestions   from './components/KeywordsSuggestions';
import CompetitorsKeywords from './components/CompetitorsKeywords';
import CompetitorsDomain from './components/CompetitorsDomain';
import PagesTrafficTable from './components/PagesTrafficTable';
import CountryTrafficTable from './components/CountryTrafficTable';
import DeviceTrafficTable from './components/DeviceTrafficTable';
import QueryTrafficTable from './components/QueryTrafficTable';
import KeywordsTable from './components/KeywordsTable'
import KeywordsSuggestionsTable from './components/KeywordsSuggestionsTable'
import CompetitorsKeywordsTable from './components/CompetitorsKeywordsTable';
import DomainCompetitorTable from "./components/DomainCompetitorTable";
import CompetitorsAnalysis from "./components/CompetitorsAnalysis";
import CreateTrends from "./components/CreateTrends";
import Chat from "./components/Chat";
import TrendsTable from "./components/TrendsTable";
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { API_URL } from './config';
import axios from 'axios';
import copyIcon from './assets/copy_icon.png';


ChartJS.register(ArcElement, Tooltip, Legend);

function App() {
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [responseData, setResponseData] = useState([]); // Store multiple responses
  const [loading, setLoading] = useState(false); // Handle loading state
  const [refineTypes, setRefineTypes] = useState([]); // Store refine types
  const [showRefineOptions, setShowRefineOptions] = useState([]); // Manage visibility of refine options for each response
  const [csrfToken, setCsrfToken] = useState(''); // State for CSRF token
  const [requestCount, setRequestCount] = useState(1); // Track the number of requests
  const [userInfo, setUserInfo] = useState(null);
  const [analyticsData, setAnalyticsData] = useState({ countryData: null, cityData: null });
  const [searchData, setSearchData] = useState(null);
  const [trendsData, setTrendsData] = useState(null);
  const [keywordData, setKeywordData] = useState(null);
  const [keywordCompetitorData, setKeywordCompetitorData] = useState(null);
  const [domainCompetitorData, setDomainCompetitorData] = useState(null);
  const [analysisCompetitorData, setAnalysisCompetitorData] = useState(null);
  const [keywordSuggestionData, setKeywordSuggestionData] = useState(null);


  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const token = localStorage.getItem('access_token');
        const response = await axios.get(`${API_URL}/users/me/`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setUserInfo(response.data); // Save user info (username, email, etc.)
      } catch (error) {
        console.error('Error fetching user information:', error);
      }
    };

    fetchUserInfo();
  }, []);

  // Fetch CSRF token from the backend
  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        const response = await axios.get(`${API_URL}/csrf-token/`);
        setCsrfToken(response.data.csrfToken);
      } catch (error) {
        console.error('Error fetching CSRF token:', error);
      }
    };
    fetchCsrfToken();
  }, []);

  // Fetch refine types when the component mounts
  useEffect(() => {
const fetchRefineTypes = async () => {
  try {
    // Retrieve the token from localStorage or any other secure storage
    const token = localStorage.getItem('access_token');

    const response = await axios.get(`${API_URL}/marketing_content/api/refine-types/`, {
      headers: {
        'Authorization': `Bearer ${token}`, // Add the Authorization header
        'X-CSRFToken': csrfToken, // Include the CSRF token
      },
    });

    setRefineTypes(response.data); // Save the refine types in state
  } catch (error) {
    console.error('Error fetching refine types:', error.response || error);
  }
};
    if (csrfToken) {
      fetchRefineTypes();
    }
  }, [csrfToken]);

  // Separate functions for handling API calls for different components
  const handleCreateAdResponse = async (formData) => {
    return await makeApiRequests(`${API_URL}/marketing_content/api/create-ad/`, formData);
  };

  const handleCreateSocialResponse = async (formData) => {
    return await makeApiRequests(`${API_URL}/marketing_content/api/create-social/`, formData);
  };

  const handleCreatePRResponse = async (formData) => {
    return await makeApiRequests(`${API_URL}/marketing_content/api/create-pr/`, formData);
  };
    const handleCreateEmailResponse = async (formData) => {
    return await makeApiRequests(`${API_URL}/marketing_content/api/create-email/`, formData);
  };
  const handleCreateEcommerceResponse = async (formData) => {
    return await makeApiRequests(`${API_URL}/marketing_content/api/create-ecommerce/`, formData);
  };
  const handleCreateGeneralResponse = async (formData) => {
    return await makeApiRequests(`${API_URL}/marketing_content/api/create-general/`, formData);
  };
  const handleCreateRefineResponse = async (formData) => {
    return await makeApiRequests(`${API_URL}/marketing_content/api/create-refine/`, formData);
  };
  const handleCreateBoostResponse = async (formData) => {
    return await makeApiRequests(`${API_URL}/marketing_content/api/create-content-booster/`, formData);
  };
  const handleCreateTranslateResponse = async (formData) => {
    return await makeApiRequests(`${API_URL}/marketing_content/api/translate-content/`, formData);
  };
  const handleCreateImageResponse = async (formData) => {
    return await makeApiRequests(`${API_URL}/marketing_content/api/create-image/`, formData);
  };

  const handleCreateImageAIResponse = async (formData) => {
    return await makeApiRequests(`${API_URL}/marketing_content/api/create-image-ai/`, formData);
  };
  const handleCopyToClipboard = (text) => {
  navigator.clipboard.writeText(text)
    .then(() => {
      alert("Content copied to clipboard!");
    })
    .catch((error) => {
      console.error("Failed to copy text:", error);
      alert("Failed to copy content. Please try again.");
    });
};

  const handleAnalyticsData = (data) => {
    console.log("Setting analyticsData in state:", data);
    setAnalyticsData({
      countryData: data.countryData,
      cityData: data.returningUsersData,
      landingPageData: data.landingPageData,
      landingPageFilterData: data.landingPageFilterData,
      landingPageBounceRateData: data.landingPageBounceRateData,
      landingPageTitleData: data.landingPageTitleData,
      landingSourceMediumData: data.landingSourceMediumData,
      landingDefaultChannelData: data.landingDefaultChannelData,
      GenderTrafficData: data.GenderTrafficData,
      AgeTrafficData: data.AgeTrafficData,
      InterestTrafficData: data.InterestTrafficData
    });
  };

  const handleSearchData = (data) => {
    console.log("Setting searchData in state:", data);
    setSearchData({
      queriesData: data.queriesData,
      pagesData: data.pagesData,
      countriesData: data.countriesData,
      devicesData: data.devicesData,
    });
  };

  const handleTrendsData = (data) => {
    console.log("Setting searchData in state:", data);
    setTrendsData({
      trendsData: data.trendsData,
    });
  };


  const handleKeywordsData = (data) => {
    console.log("Keywords data received in handleKeywordsData:", data);
    setKeywordData({
      keywordsData: data,
    });
  };

  const handleKeywordsSuggestionData = (data) => {
    console.log("Keywords data received in handleKeywordsSuggestionData:", data);
    setKeywordSuggestionData({
      keywordsSuggestionData: data.suggestionsData,
    });
  };
  const handleKeywordsCompetitorData = (data) => {
    console.log("Keywords data received in handleKeywordsCompetitorData:", data);
    setKeywordCompetitorData({
      keywordCompetitorData: data.competitorsData,
    });
  };

  const handleDomainCompetitorData = (data) => {
    console.log("Keywords data received in handleDomainCompetitorData:", data);
    setDomainCompetitorData({
      domainCompetitorData: data.domainCompetitorData,
    });
  };

const handleAnalysisCompetitorData = (data) => {
  console.log("Keywords data received in handleAnalysisCompetitorData:", data);
  setAnalysisCompetitorData(data); // Set the data directly
};




const makeApiRequests = async (apiEndpoint, formData) => {
  try {
    console.log("Starting API request...");
    setLoading(true);
    console.log("Loading state set to true");

    // Retrieve the token from localStorage or any other secure storage
    const token = localStorage.getItem('access_token');

    // Create an array of API requests based on the requestCount
    const requests = Array.from({ length: requestCount }, (_, index) => {
      console.log(`Creating request ${index + 1}`);
      const formDataFormatted = new URLSearchParams();
      for (let key in formData) {
        formDataFormatted.append(key, formData[key]);
      }
      return axios.post(
        apiEndpoint,
        formDataFormatted,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-CSRFToken': csrfToken,
            'Authorization': `Bearer ${token}`, // Add the Authorization header
          },
        }
      );
    });

    console.log("All requests created. Sending requests...");

    // Wait for all requests to complete
    const responses = await Promise.all(requests);
    console.log("All requests completed.");

    const responseDataArray = responses.map((response, index) => {
      console.log(`Response ${index + 1}:`, response.data);
      return response.data;
    });

    console.log("API responses:", responseDataArray);

    // Handle differently if the selected component is CreateImage or CreateImageAI
    if (selectedComponent === 'CreateImage' || selectedComponent === 'CreateImageAI') {
      console.log("Handling CreateImage or CreateImageAI responses...");
      setResponseData(responseDataArray); // Store URLs directly
    } else {
      console.log("Handling other component responses...");
      // Log IDs only for components that return structured data with IDs
      responseDataArray.forEach((response, index) => {
        if (response.id) {
          console.log(`Response at index ${index} has ID: ${response.id}`);
        } else {
          console.warn(`No ID found in response at index ${index}. Response data:`, response);
        }
      });
      setResponseData(responseDataArray); // Store all responses
    }

    setShowRefineOptions(Array(responseDataArray.length).fill(false)); // Initialize refine options visibility for each response
    console.log("Setting loading state to false...");
    setLoading(false);
    console.log("Loading state set to false");
  } catch (error) {
    console.error('Error submitting multiple requests:', error.response || error);
    console.log("An error occurred, setting loading state to false");
    setLoading(false);
  }
};

const renderUserInfo = () => {
  return userInfo ? (
    <div className="user-info">
      <span>Welcome, {userInfo.first_name || userInfo.username}</span>
      <button className="logout-button" onClick={handleLogout}>
        Logout
      </button>
    </div>
  ) : (
    <div className="user-info">Loading user info...</div>
  );
};


  const handleLogout = async () => {
    const refreshToken = localStorage.getItem('refresh_token');
    try {
      await axios.post(`${API_URL}/users/logout/`, { refresh_token: refreshToken }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      });
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      document.cookie = 'sessionid=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;';
      document.cookie = 'csrftoken=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;';
      window.location.href = '/login';
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };


  const handleResponse = (formData) => {
    if (selectedComponent === 'CreateAd') {
      setKeywordData(null);
      handleCreateAdResponse(formData);
    } else if (selectedComponent === 'CreateSocial') {
      setKeywordData(null);
      handleCreateSocialResponse(formData);
    } else if (selectedComponent === 'CreatePR') {
      setKeywordData(null);
      handleCreatePRResponse(formData);
    } else if (selectedComponent === 'CreateEmail') {
      handleCreateEmailResponse(formData);
      setKeywordData(null);
    } else if (selectedComponent === 'CreateEcommerce') {
      setKeywordData(null);
      handleCreateEcommerceResponse(formData);
    }
    else if (selectedComponent === 'CreateGeneral') {
      setKeywordData(null);
      handleCreateGeneralResponse(formData);
    }
    else if (selectedComponent === 'CreateRefine') {
      setKeywordData(null);
      handleCreateRefineResponse(formData);
    }
    else if (selectedComponent === 'CreateBoost') {
      setKeywordData(null);
      handleCreateBoostResponse(formData);
    }
    else if (selectedComponent === 'CreateTranslate') {
      handleCreateTranslateResponse(formData);
    }
    else if (selectedComponent === 'CreateImage') {
      handleCreateImageResponse(formData);
    } else if (selectedComponent === 'CreateImageAI') {
      handleCreateImageAIResponse(formData);
    }


  };


const handleMagicBoost = async (index) => {
  const id = responseData[index]?.id;
  if (!id) return;

  const apiEndpoint = selectedComponent === 'CreateAd'
    ? `${API_URL}/marketing_content/api/boost-ad/${id}/`
    : selectedComponent === 'CreateSocial'
    ? `${API_URL}/marketing_content/api/boost-social/${id}/`
    : selectedComponent === 'CreateBoost'
    ? `${API_URL}/marketing_content/api/boost-content/${id}/`
    : selectedComponent === 'CreateEcommerce'
    ? `${API_URL}/marketing_content/api/boost-ecommerce/${id}/`
    : selectedComponent === 'CreateEmail'
    ? `${API_URL}/marketing_content/api/boost-email/${id}/`
    : selectedComponent === 'CreateGeneral'
    ? `${API_URL}/marketing_content/api/boost-general/${id}/`
    : selectedComponent === 'CreateRefine'
    ? `${API_URL}/marketing_content/api/boost-refine/${id}/`
    : selectedComponent === 'CreateSocial'
    ? `${API_URL}/marketing_content/api/boost-social/${id}/`
    : selectedComponent === 'CreateTranslate'
    ? `${API_URL}/marketing_content/api/boost-translate/${id}/`
    : `${API_URL}/marketing_content/api/boost-pr/${id}/`;

  try {
    setLoading(true);

    // Retrieve the token from localStorage or any other secure storage
    const token = localStorage.getItem('access_token');

    const response = await axios.patch(apiEndpoint, {}, {
      headers: {
        'X-CSRFToken': csrfToken, // Include the CSRF token
        'Authorization': `Bearer ${token}`, // Add the Authorization header
      },
    });

    setResponseData((prevData) =>
      prevData.map((data, i) =>
        i === index ? { ...data, content: response.data.content || data.content, scale: response.data.scale || data.scale } : data
      )
    );
  } catch (error) {
    console.error('Error boosting:', error.response || error);
  } finally {
    setLoading(false);
  }
};


const handleRefine = async (index, refinementType) => {
  const id = responseData[index]?.id;
  if (!id) return;

  const apiEndpoint = selectedComponent === 'CreateAd'
    ? `${API_URL}/marketing_content/api/refine-ad/${id}/${encodeURIComponent(refinementType)}`
    : selectedComponent === 'CreateSocial'
    ? `${API_URL}/marketing_content/api/refine-social/${id}/${encodeURIComponent(refinementType)}`
    : selectedComponent === 'CreateBoost'
    ? `${API_URL}/marketing_content/api/refine-content/${id}/${encodeURIComponent(refinementType)}`
    : selectedComponent === 'CreateEcommerce'
    ? `${API_URL}/marketing_content/api/refine-ecommerce/${id}/${encodeURIComponent(refinementType)}`
    : selectedComponent === 'CreateEmail'
    ? `${API_URL}/marketing_content/api/refine-email/${id}/${encodeURIComponent(refinementType)}`
    : selectedComponent === 'CreateGeneral'
    ? `${API_URL}/marketing_content/api/refine-general/${id}/${encodeURIComponent(refinementType)}`
    : selectedComponent === 'CreateRefine'
    ? `${API_URL}/marketing_content/api/refine-refine/${id}/${encodeURIComponent(refinementType)}`
    : selectedComponent === 'CreateSocial'
    ? `${API_URL}/marketing_content/api/refine-social/${id}/${encodeURIComponent(refinementType)}`
    : selectedComponent === 'CreateTranslate'
    ? `${API_URL}/marketing_content/api/refine-translate/${id}/${encodeURIComponent(refinementType)}`
    : `${API_URL}/marketing_content/api/refine-pr/${id}/${encodeURIComponent(refinementType)}`;

  try {
    setLoading(true);

    // Retrieve the token from localStorage or any other secure storage
    const token = localStorage.getItem('access_token');

    const response = await axios.patch(apiEndpoint, {}, {
      headers: {
        'X-CSRFToken': csrfToken, // Include the CSRF token
        'Authorization': `Bearer ${token}`, // Add the Authorization header
      },
    });

    setResponseData((prevData) =>
      prevData.map((data, i) =>
        i === index ? { ...data, content: response.data.content || data.content, scale: response.data.scale || data.scale } : data
      )
    );
  } catch (error) {
    console.error('Error refining:', error.response || error);
  } finally {
    setLoading(false);
  }
};

  const renderSelectedComponent = () => {
    if (selectedComponent === 'CreateAd') {
      return (
        <CreateAd
          onResponse={handleResponse}
          onLoading={setLoading}
          onSetRequestCount={setRequestCount}
          onKeywordsFetched={handleKeywordsData}
        />
      );
    } else if (selectedComponent === 'CreateSocial') {
      return (
        <CreateSocial
          onResponse={handleResponse}
          onLoading={setLoading}
          onSetRequestCount={setRequestCount}
          onKeywordsFetched={handleKeywordsData}
        />
      );
    } else if (selectedComponent === 'CreatePR') {
      return (
        <CreatePR
          onResponse={handleResponse}
          onLoading={setLoading}
          onSetRequestCount={setRequestCount}
          onKeywordsFetched={handleKeywordsData}
        />
      );
    } else if (selectedComponent === 'CreateEmail') {
      return (
        <CreateEmail
          onResponse={handleResponse}
          onLoading={setLoading}
          onSetRequestCount={setRequestCount}
          onKeywordsFetched={handleKeywordsData}
        />
      );
    } else if (selectedComponent === 'CreateEcommerce') {
      return (
        <CreateEcommerce
          onResponse={handleResponse}
          onLoading={setLoading}
          onSetRequestCount={setRequestCount}
          onKeywordsFetched={handleKeywordsData}
        />
      );
    }
    else if (selectedComponent === 'CreateGeneral') {
      return (
        <CreateGeneral
          onResponse={handleResponse}
          onLoading={setLoading}
          onSetRequestCount={setRequestCount}
          onKeywordsFetched={handleKeywordsData}
        />
      );
    }
    else if (selectedComponent === 'CreateRefine') {
      return (
        <CreateRefine
          onResponse={handleResponse}
          onLoading={setLoading}
          onSetRequestCount={setRequestCount}
          onKeywordsFetched={handleKeywordsData}
        />
      );
    }
    else if (selectedComponent === 'CreateTranslate') {
      return (
        <CreateTranslate
          onResponse={handleResponse}
          onLoading={setLoading}
          onSetRequestCount={setRequestCount}
        />
      );
    }


    else if (selectedComponent === 'CreateBoost') {
      return (
        <CreateBoost
          onResponse={handleResponse}
          onLoading={setLoading}
          onSetRequestCount={setRequestCount}
          onKeywordsFetched={handleKeywordsData}
        />
      );
    }
  else if (selectedComponent === 'CreateAnalytics') {
      return (
        <CreateAnalytics
          onFetchDataComplete={handleAnalyticsData} // Pass callback here
          csrfToken={csrfToken}
        />
      );
    }
    else if (selectedComponent === 'CreateSearch') {
      return (
        <CreateSearch
          onFetchDataComplete={handleSearchData} // Pass callback here
          csrfToken={csrfToken}
        />
      );
    }
    else if (selectedComponent === 'CreateTrends') {
      return (
        <CreateTrends
          onFetchDataComplete={handleTrendsData} // Pass callback here
          csrfToken={csrfToken}
        />
      );
    }

  else if (selectedComponent === 'KeywordsIdeas') {
    console.log("Rendering KeywordsIdeas component"); // Log for rendering confirmation
    return (
      <KeywordsIdeas
        onFetchDataComplete={handleKeywordsData} // Pass callback here
        csrfToken={csrfToken}
      />
    );
  }

    else if (selectedComponent === 'KeywordsSuggestions') {
    console.log("Rendering KeywordsSuggestions component"); // Log for rendering confirmation
    return (
      <KeywordsSuggestions
        onFetchDataComplete={handleKeywordsSuggestionData} // Pass callback here
        csrfToken={csrfToken}
      />
    );
  }
  else if (selectedComponent === 'CompetitorsKeywords') {
    console.log("Rendering CompetitorsKeywords component"); // Log for rendering confirmation
    return (
      <CompetitorsKeywords
        onFetchDataComplete={handleKeywordsCompetitorData} // Pass callback here
        csrfToken={csrfToken}
      />
    );
  }

  else if (selectedComponent === 'CompetitorsDomain') {
    console.log("Rendering CompetitorsDomain component"); // Log for rendering confirmation
    return (
      <CompetitorsDomain
        onFetchDataComplete={handleDomainCompetitorData} // Pass callback here
        csrfToken={csrfToken}
      />
    );
  }
  else if (selectedComponent === 'CompetitorsAnalysis') {
    console.log("Rendering CompetitorsAnalysis component"); // Log for rendering confirmation
    return (
      <CompetitorsAnalysis
        onFetchDataComplete={handleAnalysisCompetitorData} // Pass callback here
        csrfToken={csrfToken}
      />
    );
  }


    else if (selectedComponent === 'CreateImage') {
      return <CreateImage onResponse={handleResponse} onLoading={setLoading} />;
    } else if (selectedComponent === 'CreateImageAI') {
      return <CreateImageAI onResponse={handleResponse} onLoading={setLoading} />;
    }

    return null;
  };

  const renderChart = (scale) => {
    const data = {
      datasets: [
        {
          data: [scale, 100 - scale],
          backgroundColor: ['#FFD700', '#E0E0E0'],
          borderWidth: 2,
        },
      ],
    };

    const options = {
      cutout: '70%',
      responsive: true,
      plugins: {
        legend: { display: false },
      },
    };

    return (
      <div className="chart-wrapper">
        <Doughnut data={data} options={options} width={200} height={200} />
      </div>
    );
  };

  return (
      <div className="app-container">
        <Sidebar setComponent={setSelectedComponent}/>

        <div
            className={`content ${selectedComponent === 'Chat' ? 'hidden' : (selectedComponent || responseData.length > 0 ? 'visible' : 'hidden')}`}>
          {renderSelectedComponent()}
        </div>


        <div className="response-section">
          {renderUserInfo()}
          {loading ? (
              <div className="loading-indicator">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <p>Processing...</p>
              </div>
          ) : selectedComponent === 'Chat' ? (
              <div className="response-box">
              <Chat
                  onClose={() => setSelectedComponent(null)}
                  csrfToken={csrfToken}
              />
                </div>
          ) : selectedComponent === 'CreateAnalytics' && analyticsData ? (
              <>
                <div className="response-box">
                  <h3>Country Traffic</h3>
                  <CountryAnalyticsChart countryData={analyticsData.countryData}/>
                </div>
                <div className="response-box">
                  <h3>City Returning Users</h3>
                  <CityAnalyticsChart cityData={analyticsData.cityData}/>
                </div>
                <div className="response-box">
                  <h3>Landing page</h3>
                  <LandingPageSessionsChart landingPageData={analyticsData.landingPageData}/>
                </div>
                <div className="response-box">
                  <h3>Landing page filtered</h3>
                  <LandingPageSessionsChart landingPageData={analyticsData.landingPageFilterData}/>
                </div>
                <div className="response-box">
                  <h3>Landing page bounce</h3>
                  <LandingPageBounceChart landingPageData={analyticsData.landingPageBounceRateData}/>
                </div>
                <div className="response-box">
                  <EngagedSessionsChart sessionData={analyticsData.landingPageTitleData}/>
                </div>
                <div className="response-box">
                  <SourceMediumSessionsChart sourceMediumData={analyticsData.landingSourceMediumData}/>
                </div>
                <div className="response-box">
                  <ChannelGroupEngagedSessionsChart channelGroupData={analyticsData.landingDefaultChannelData}/>
                </div>
                <div className="response-box">
                  <h3>Gender traffic</h3>
                  <GenderTrafficChart data={analyticsData.GenderTrafficData}/>
                </div>
                <div className="response-box">
                  <h3>Age traffic</h3>
                  <AgeTrafficChart data={analyticsData.AgeTrafficData}/>
                </div>
                <div className="response-box">
                  <h3>Interest traffic</h3>
                  <InterestTrafficChart data={analyticsData.InterestTrafficData}/>
                </div>
              </>
          ) : selectedComponent === 'CreateSearch' && searchData ? (
              <>
                <div className="response-box">
                  <h3>Top Queries</h3>
                  {searchData.queriesData && searchData.queriesData.length > 0 ? (
                      <QueryTrafficTable data={searchData.queriesData}/>
                  ) : (
                      <p>No data available for queries.</p>
                  )}
                </div>

                <div className="response-box">
                  <h3>Top Pages</h3>
                  {searchData.pagesData && searchData.pagesData.length > 0 ? (
                      <PagesTrafficTable data={searchData.pagesData}/>
                  ) : (
                      <p>No data available for pages.</p>
                  )}
                </div>

                <div className="response-box">
                  <h3>Top Countries</h3>
                  {searchData.countriesData && searchData.countriesData.length > 0 ? (
                      <CountryTrafficTable data={searchData.countriesData}/>
                  ) : (
                      <p>No data available for countries.</p>
                  )}
                </div>

                <div className="response-box">
                  <h3>Top Devices</h3>
                  {searchData.devicesData && searchData.devicesData.length > 0 ? (
                      <DeviceTrafficTable data={searchData.devicesData}/>
                  ) : (
                      <p>No data available for devices.</p>
                  )}
                </div>
              </>
          ) : (selectedComponent === 'CreateAd' || selectedComponent === 'CreateSocial' || selectedComponent === 'CreatePR' || selectedComponent === 'CreateEmail' || selectedComponent === 'CreateEcommerce' || selectedComponent === 'CreateGeneral' || selectedComponent === 'CreateRefine' || selectedComponent === 'CreateBoost' || selectedComponent === 'KeywordsIdeas') && keywordData ? (
              <div className="response-box">
                <h3>Keywords</h3>
                {keywordData ? (
                    <KeywordsTable data={keywordData}/>
                ) : (
                    <p>No data available for keywords ideas.</p>
                )}
              </div>
          ) : selectedComponent === 'KeywordsSuggestions' && keywordSuggestionData ? (
              <div className="response-box">
                <h3>Keywords Suggestions Data</h3>
                <div className="response-box">
                  {keywordSuggestionData ? (
                      <KeywordsSuggestionsTable data={keywordSuggestionData.keywordsSuggestionData}/>
                  ) : (
                      <p>No data available for keyword suggestions.</p>
                  )}
                </div>
              </div>
          ) : selectedComponent === 'CompetitorsKeywords' && keywordCompetitorData ? (
              <div className="response-box">
                <h3>Competitors Keywords</h3>
                {keywordCompetitorData ? (
                    <CompetitorsKeywordsTable data={keywordCompetitorData.keywordCompetitorData}/>
                ) : (
                    <p>No data available for competitors keywords.</p>
                )}
              </div>
          ) : selectedComponent === 'CompetitorsDomain' && domainCompetitorData ? (
              <div className="response-box">
                <h3>Competitors Domain</h3>
                {domainCompetitorData ? (
                    <DomainCompetitorTable data={domainCompetitorData.domainCompetitorData}/>
                ) : (
                    <p>No data available for competitors domain.</p>
                )}
              </div>
          ) : selectedComponent === 'CompetitorsAnalysis' && analysisCompetitorData ? (
              <div className="response-box">
                <h3>Competitors Analysis</h3>
                <pre>{analysisCompetitorData}</pre>
                {/* Display as plain text */}
              </div>
          ) : selectedComponent === 'CreateTrends' && trendsData ? (
              <div className="response-box">
                <h3>Google trends</h3>
                <TrendsTable data={trendsData.trendsData}/>
              </div>
          ) : responseData.length ? (
              <div className="response-box">
                <h3>Generated Content</h3>
                {responseData.map((response, index) => (
                    <div key={index} className="response-item">
                      {selectedComponent === 'CreateImage' || selectedComponent === 'CreateImageAI' ? (
                          Array.isArray(response) ? (
                              <div className="image-gallery">
                                {response.map((url, idx) => (
                                    <img key={idx} src={url} alt={`Generated ${idx}`} className="generated-image"/>
                                ))}
                              </div>
                          ) : (
                              <p>No images available.</p>
                          )
                      ) : (
                          <>
                            <pre className="styled-pre">
                              {response.content}
                            </pre>
                            {response.scale && (
                                <div className="chart-container">
                                  {renderChart(response.scale)}
                                  <p className="scale-value">{response.scale}</p>
                                </div>
                            )}
                            {response.id ? (
                                <>
                                  <button onClick={() => handleMagicBoost(index)} className="magic-boost-btn">
                                    Magic Boost
                                  </button>
                                  <button
                                      onClick={() =>
                                          setShowRefineOptions((prev) =>
                                              prev.map((value, i) => (i === index ? !value : value))
                                          )
                                      }
                                      className="magic-boost-btn"
                                  >
                                    Refine
                                  </button>
                                  {showRefineOptions[index] && (
                                      <div className="refine-options">
                                        {refineTypes.map((type) => (
                                            <button
                                                key={type.value}
                                                onClick={() => handleRefine(index, type.label)}
                                                className="refine-option"
                                            >
                                              {type.label}
                                            </button>
                                        ))}
                                      </div>
                                  )}
                                </>
                            ) : (
                                <p>No ID available for actions.</p>
                            )}
                          </>
                      )}
                      <button
                          onClick={() => handleCopyToClipboard(response.content)}
                          className="copy-button"
                      >
                        <img src={copyIcon} alt="Copy Icon" className="copy-icon"/>
                      </button>

                    </div>
                ))}
              </div>
          ) : (
              <h1>No response yet. Submit the form to see the result.</h1>
          )}
        </div>
      </div>

  );
}

export default App;
