import React from 'react';
import { Bar } from 'react-chartjs-2';

const InterestTrafficChart = ({ data }) => {
  // Check if data contains the expected array or if it's nested within an object
  const interests = Array.isArray(data) ? data : data?.data || [];

  if (interests.length === 0) {
    return <p>Data is still not loaded.</p>;
  }

  // Map data for the top 10 interests only to keep each chart readable
  const topRows = interests.slice(0, 10);

  // Data for Engaged Sessions chart
  const engagedSessionsData = {
    labels: topRows.map(row => row.interest),
    datasets: [
      {
        label: 'Engaged Sessions',
        data: topRows.map(row => row.engaged_sessions),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderWidth: 1,
      },
    ],
  };

  // Data for Average Engagement Time chart
  const averageEngagementTimeData = {
    labels: topRows.map(row => row.interest),
    datasets: [
      {
        label: 'Average Engagement Time (s)',
        data: topRows.map(row => {
          const [minutes, seconds] = row.average_engagement_time.split("m ").map((val) => parseInt(val, 10));
          return minutes * 60 + seconds; // Convert time to seconds for consistent display
        }),
        backgroundColor: 'rgba(153, 102, 255, 0.6)',
        borderWidth: 1,
      },
    ],
  };

  // Options for both charts, customized for each
  const engagedSessionsOptions = {
    responsive: true,
    plugins: {
      legend: { position: 'top' },
      title: {
        display: true,
        text: 'Engaged Sessions by Interest',
        font: { size: 18 },
      },
      tooltip: {
        callbacks: {
          label: context => `${context.raw} sessions`,
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Engaged Sessions',
        },
      },
      x: {
        title: {
          display: true,
          text: 'Interest',
        },
      },
    },
  };

  const averageEngagementTimeOptions = {
    responsive: true,
    plugins: {
      legend: { position: 'top' },
      title: {
        display: true,
        text: 'Average Engagement Time by Interest',
        font: { size: 18 },
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const seconds = context.raw;
            const minutes = Math.floor(seconds / 60);
            const remainingSeconds = seconds % 60;
            return `${minutes}m ${remainingSeconds}s engagement time`;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Average Engagement Time (s)',
        },
      },
      x: {
        title: {
          display: true,
          text: 'Interest',
        },
      },
    },
  };

  return (
    <div>
      <div style={{ marginBottom: '2rem' }}>
        <Bar data={engagedSessionsData} options={engagedSessionsOptions} />
      </div>
      <div>
        <Bar data={averageEngagementTimeData} options={averageEngagementTimeOptions} />
      </div>
    </div>
  );
};

export default InterestTrafficChart;
