import React from 'react';
import { Bar } from 'react-chartjs-2';

const ChannelGroupEngagedSessionsChart = ({ channelGroupData }) => {
  if (!channelGroupData || !channelGroupData.rows) {
    return <p>Data is still not loaded.</p>;
  }

  // Data for the Default Channel Group Engaged Sessions chart
  const chartData = {
    labels: channelGroupData.rows.map(row => row.dimensionValues[0].value),
    datasets: [
      {
        label: 'Engaged Sessions',
        data: channelGroupData.rows.map(row => parseInt(row.metricValues[0].value, 10)),
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
      },
    ],
  };

  return (
    <div>
      <h3>Engaged Sessions by Default Channel Group</h3>
      <Bar
        data={chartData}
        options={{
          indexAxis: 'y', // Horizontal bar chart
          scales: {
            x: {
              beginAtZero: true,
            },
          },
          responsive: true,
          plugins: {
            legend: {
              display: true,
            },
          },
        }}
      />
    </div>
  );
};

export default ChannelGroupEngagedSessionsChart;
