import React, { useState } from 'react';
import axios from 'axios';
import { API_URL } from '../config';
import '../styles/KeywordsIdeas.css';

const KeywordsIdeas = ({ onFetchDataComplete, csrfToken }) => {
  const [keywords, setKeywords] = useState('');
  const [locationName, setLocationName] = useState('');
  const [languageName, setLanguageName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleFetchData = async () => {
    setLoading(true);
    setError(null);

    const token = localStorage.getItem('access_token');

    console.log("CSRF Token:", csrfToken);

    try {
      const keywordsResponse = await axios.post(
        `${API_URL}/keywords_ideas/`,
        {
          keywords,
          location_name: locationName,
          language_name: languageName,
        },
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      onFetchDataComplete({
        keywordsData: keywordsResponse.data,
      });

      setError(null);
    } catch (error) {
      console.error('Error fetching keywords ideas:', error);
      setError('Failed to fetch keywords ideas. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="keywords-ideas-container">
      <h1>Keywords Ideas</h1>
      {error && <p className="error-message">{error}</p>}
      <input
        className="input-field"
        value={keywords}
        onChange={(e) => setKeywords(e.target.value)}
        placeholder="Keywords"
      />
      <input
        className="input-field"
        value={locationName}
        onChange={(e) => setLocationName(e.target.value)}
        placeholder="Location Name"
      />
      <input
        className="input-field"
        value={languageName}
        onChange={(e) => setLanguageName(e.target.value)}
        placeholder="Language Name"
      />
      <button className="fetch-button" onClick={handleFetchData} disabled={loading}>
        {loading ? 'Fetching...' : 'Fetch Ideas'}
      </button>
    </div>
  );
};

export default KeywordsIdeas;
