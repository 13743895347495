import React from 'react';
import { Bar } from 'react-chartjs-2';

const LandingPageBounceChart = ({ landingPageData }) => {
  if (!landingPageData || !landingPageData.rows) {
    return <p>Data is still not loaded.</p>;
  }

  // Data for Sessions chart
  const sessionData = {
    labels: landingPageData.rows.map(row => row.dimensionValues[0].value),
    datasets: [
      {
        label: 'Sessions',
        data: landingPageData.rows.map(row => parseInt(row.metricValues[0].value, 10)),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
    ],
  };

  // Data for Bounce Rate chart (converted from ratio to percentage)
  const bounceRateData = {
    labels: landingPageData.rows.map(row => row.dimensionValues[0].value),
    datasets: [
      {
        label: 'Bounce Rate (%)',
        data: landingPageData.rows.map(row => parseFloat(row.metricValues[1].value) * 100), // Convert to percentage
        backgroundColor: 'rgba(255, 99, 132, 0.6)',
      },
    ],
  };

  return (
    <div>
      <Bar
        data={sessionData}
        options={{
          indexAxis: 'y', // Horizontal bar chart
          scales: {
            x: {
              beginAtZero: true,
            },
          },
          responsive: true,
          plugins: {
            legend: {
              display: true,
            },
          },
        }}
      />

      <h3>Bounce Rate by Landing Page</h3>
      <Bar
        data={bounceRateData}
        options={{
          indexAxis: 'y', // Horizontal bar chart
          scales: {
            x: {
              beginAtZero: true,
              ticks: {
                callback: value => `${value}%`, // Add percentage symbol to x-axis labels
              },
            },
          },
          responsive: true,
          plugins: {
            legend: {
              display: true,
            },
          },
        }}
      />
    </div>
  );
};

export default LandingPageBounceChart;
