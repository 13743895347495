import React from 'react';
import { Bar } from 'react-chartjs-2';

const LandingPageSessionsChart = ({ landingPageData }) => {
  if (!landingPageData || !landingPageData.rows) {
    return <p>Data is still not loaded.</p>;
  }

  const sessionData = {
    labels: landingPageData.rows.map(row => row.dimensionValues[0].value),
    datasets: [
      {
        label: 'Sessions',
        data: landingPageData.rows.map(row => parseInt(row.metricValues[0].value, 10)),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
    ],
  };

  const engagementData = {
    labels: landingPageData.rows.map(row => row.dimensionValues[0].value),
    datasets: [
      {
        label: 'Avg Engagement Time (seconds)',
        data: landingPageData.rows.map(row => parseFloat(row.metricValues[2].value)),
        backgroundColor: 'rgba(153, 102, 255, 0.6)',
      },
    ],
  };

  return (
    <div>
      <Bar
        data={sessionData}
        options={{
          indexAxis: 'y',
          scales: {
            x: {
              beginAtZero: true,
            },
          },
          responsive: true,
          plugins: {
            legend: {
              display: true,
            },
          },
        }}
      />

      <h3>Average Engagement Time per Session</h3>
      <Bar
        data={engagementData}
        options={{
          indexAxis: 'y',
          scales: {
            x: {
              beginAtZero: true,
            },
          },
          responsive: true,
          plugins: {
            legend: {
              display: true,
            },
          },
        }}
      />
    </div>
  );
};

export default LandingPageSessionsChart;
