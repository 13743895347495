import React from 'react';
import '../styles/KeywordsSuggestionsTable.css';

const KeywordsSuggestionsTable = ({ data }) => {
  const { keywords, location_name, limit, include_seed_keyword, suggestions } = data;

  return (
    <div className="keywords-suggestions-table-container">
      <div className="keywords-suggestions-overview">
        <p><strong>Keywords:</strong> {keywords}</p>
        <p><strong>Location Name:</strong> {location_name}</p>
        <p><strong>Limit:</strong> {limit}</p>
        <p><strong>Include Seed Keyword:</strong> {include_seed_keyword ? 'Yes' : 'No'}</p>
      </div>

      <table className="keywords-suggestions-table">
        <thead>
          <tr>
            <th>Keyword</th>
            <th>Search Volume</th>
            <th>Keyword Difficulty</th>
          </tr>
        </thead>
        <tbody>
          {suggestions.map((suggestion, index) => (
            <tr key={index}>
              <td>{suggestion.keyword}</td>
              <td>{suggestion.search_volume}</td>
              <td>{suggestion.keyword_difficulty}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default KeywordsSuggestionsTable;
