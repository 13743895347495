import React, { useState } from 'react';
import axios from 'axios';
import { API_URL } from '../config';
import '../styles/CompetitorsDomain.css';

const CompetitorsDomain = ({ onFetchDataComplete, csrfToken }) => {
  const [competitorSeedUrl, setCompetitorSeedUrl] = useState('');
  const [locationName, setLocationName] = useState('');
  const [languageName, setLanguageName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleFetchData = async () => {
    setLoading(true);
    setError(null);

    const token = localStorage.getItem('access_token');
    console.log("Access Token:", token);
    console.log("CSRF Token:", csrfToken);

    if (!token || !csrfToken) {
      setError('Missing authentication tokens. Please log in again.');
      setLoading(false);
      return;
    }

    try {
      const competitorsResponse = await axios.post(
        `${API_URL}/competitors_domain/`,
        {
          competitor_seed_url: competitorSeedUrl,
          location_name: locationName,
          language_name: languageName,
        },
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      onFetchDataComplete({
        domainCompetitorData: competitorsResponse.data,
      });

      setError(null);
    } catch (error) {
      console.error('Error fetching competitors domain:', error);
      setError('Failed to fetch competitors domain. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="competitors-domain-container">
      <h1>Competitors Domain</h1>
      {error && <p className="error-message">{error}</p>}
      <input
        className="input-field"
        value={competitorSeedUrl}
        onChange={(e) => setCompetitorSeedUrl(e.target.value)}
        placeholder="Competitor Seed URL"
      />
      <input
        className="input-field"
        value={locationName}
        onChange={(e) => setLocationName(e.target.value)}
        placeholder="Location Name"
      />
      <input
        className="input-field"
        value={languageName}
        onChange={(e) => setLanguageName(e.target.value)}
        placeholder="Language Name"
      />
      <button className="fetch-button" onClick={handleFetchData} disabled={loading}>
        {loading ? 'Fetching...' : 'Fetch Competitors Domain'}
      </button>
    </div>
  );
};

export default CompetitorsDomain;
