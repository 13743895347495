// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.keywords-suggestions-table-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f4f4f8;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.keywords-suggestions-overview {
  margin-bottom: 20px;
  font-size: 1rem;
  color: #333;
}

.keywords-suggestions-table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

.keywords-suggestions-table th,
.keywords-suggestions-table td {
  padding: 12px;
  border-bottom: 1px solid #ddd;
}

.keywords-suggestions-table th {
  background-color: #f0f0f0;
  font-weight: bold;
}

.keywords-suggestions-table td {
  color: #553c9a;
}
`, "",{"version":3,"sources":["webpack://./src/styles/KeywordsSuggestionsTable.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAChB,cAAc;EACd,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,wCAAwC;AAC1C;;AAEA;EACE,mBAAmB;EACnB,eAAe;EACf,WAAW;AACb;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;;EAEE,aAAa;EACb,6BAA6B;AAC/B;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".keywords-suggestions-table-container {\r\n  width: 100%;\r\n  max-width: 800px;\r\n  margin: 0 auto;\r\n  padding: 20px;\r\n  background-color: #f4f4f8;\r\n  border-radius: 8px;\r\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\r\n}\r\n\r\n.keywords-suggestions-overview {\r\n  margin-bottom: 20px;\r\n  font-size: 1rem;\r\n  color: #333;\r\n}\r\n\r\n.keywords-suggestions-table {\r\n  width: 100%;\r\n  border-collapse: collapse;\r\n  text-align: left;\r\n}\r\n\r\n.keywords-suggestions-table th,\r\n.keywords-suggestions-table td {\r\n  padding: 12px;\r\n  border-bottom: 1px solid #ddd;\r\n}\r\n\r\n.keywords-suggestions-table th {\r\n  background-color: #f0f0f0;\r\n  font-weight: bold;\r\n}\r\n\r\n.keywords-suggestions-table td {\r\n  color: #553c9a;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
