import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { login } from '../auth';  // Auth function to store tokens locally
import { API_URL } from '../config';

function GoogleCallback({ setAuthenticated }) {
  const navigate = useNavigate();

  useEffect(() => {
    console.log("GoogleCallback component mounted.");

    // Function to fetch CSRF token
    const fetchCsrfToken = async () => {
      try {
        console.log("Fetching CSRF token...");
        const response = await axios.get(`${API_URL}/csrf-token/`, { withCredentials: true });
        const csrfToken = response.data.csrfToken;
        console.log("CSRF token received:", csrfToken);

        // Store the CSRF token in the document cookies
        document.cookie = `csrftoken=${csrfToken}; path=/`;
        return csrfToken;
      } catch (error) {
        console.error("Error fetching CSRF token:", error);
        throw new Error('Failed to fetch CSRF token');
      }
    };

    // Function to get tokens from backend after Google login
    const fetchTokensFromBackend = async (csrfToken) => {
      try {
        console.log("Making request to /check_google_login/ with CSRF token:", csrfToken);

        const response = await axios.post(`${API_URL}/check_google_login/`, {}, {
          withCredentials: true,  // Include cookies for session authentication
          headers: {
            'X-CSRFToken': csrfToken,  // Send CSRF token in request header
          },
        });

        console.log("Response received from backend:", response);

        // Check if the response contains tokens
        if (response.status === 200 && response.data.access_token && response.data.refresh_token) {
          console.log("Tokens found in response:", response.data);

          // Call the login function to store the tokens locally
          login(response.data.access_token, response.data.refresh_token);

          // Set the app's authentication state
          setAuthenticated(true);
          console.log("User authenticated, redirecting to /protected.");

          // Redirect to the protected page
          navigate('/protected');
        } else {
          console.error("No tokens found in response.");
          alert("Failed to complete Google login.");
          navigate('/login');  // Redirect to login page in case of failure
        }
      } catch (error) {
        console.error("Error during token fetching:", error);
        alert("Failed to complete Google login.");
        navigate('/login');  // Redirect to login page in case of error
      }
    };

    const handleGoogleCallback = async () => {
      try {
        console.log("Handling Google login callback...");

        const csrfToken = await fetchCsrfToken();  // Step 1: Fetch CSRF token
        console.log("CSRF Token successfully fetched:", csrfToken);

        await fetchTokensFromBackend(csrfToken);  // Step 2: Fetch tokens from backend
      } catch (error) {
        console.error("Error in Google callback flow:", error);
        alert("Google login failed. Please try again.");
        navigate('/login');
      }
    };

    console.log("Google callback useEffect triggered.");
    handleGoogleCallback();
  }, [navigate, setAuthenticated]);

  return null;  // This component doesn't render anything
}

export default GoogleCallback;
