import axios from 'axios';
import { API_URL } from './config';

// Function to log in by fetching tokens from /check_google_login/ or /users/login/
export const login = (accessToken, refreshToken) => {
  console.log("Attempting to store tokens.");
  console.log("Access Token received:", accessToken);
  console.log("Refresh Token received:", refreshToken);

  if (accessToken && refreshToken) {
    localStorage.setItem('access_token', accessToken);
    localStorage.setItem('refresh_token', refreshToken);
    console.log("Tokens stored successfully in localStorage.");
  } else {
    console.error("Tokens are undefined. Failed to store tokens.");
  }
};

// Check if the user is authenticated based on the tokens in localStorage
export const isAuthenticated = () => {
  const accessToken = localStorage.getItem('access_token');
  if (accessToken) {
    console.log("Access token found in localStorage. User is authenticated.");
    return true;
  } else {
    console.log("No access token found in localStorage. User is not authenticated.");
    return false;
  }
};

// Log out by removing tokens from localStorage
export const logout = () => {
  console.log("Logging out. Attempting to remove tokens.");
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  console.log("Tokens removed from localStorage. Logging out successful.");
};

// Function to check JWT authentication status from the backend using the stored token (for regular login)
export const checkJWTAuthentication = async () => {
  const accessToken = localStorage.getItem('access_token');
  console.log("Checking JWT authentication. Access token from localStorage:", accessToken);

  if (!accessToken) {
    console.log("No access token found in localStorage. Returning false.");
    return false;
  }

  try {
    console.log("Sending JWT token to backend for validation.");
    const response = await axios.get(`${API_URL}/check_jwt/`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    });

    console.log("Received response from JWT authentication check:", response.data);
    return response.data.authenticated;  // Assuming backend returns {authenticated: true/false}
  } catch (error) {
    console.error("Error during JWT authentication check:", error.response ? error.response.data : error.message);
    return false;
  }
};

// Function to check Google authentication status from the backend
export const checkGoogleAuthentication = async () => {
  console.log("Attempting to check Google authentication.");

  // Fetch CSRF token from cookies
  const csrfToken = document.cookie.split('; ').find(row => row.startsWith('csrftoken='))?.split('=')[1];
  console.log("CSRF token fetched from cookies:", csrfToken);

  if (!csrfToken) {
    console.error('CSRF token not found. Cannot proceed with Google authentication check.');
    return false;
  }

  try {
    console.log("Sending request to check Google authentication with CSRF token.");
    const response = await axios.get(`${API_URL}/check_google_login/`, {
      headers: {
        'X-CSRFToken': csrfToken
      },
      withCredentials: true  // Ensure session cookies are included
    });

    console.log("Received response from Google authentication check:", response.data);

    if (response.data.authenticated) {
      console.log("User is authenticated via Google.");
    } else {
      console.log("User is not authenticated via Google.");
    }

    return response.data.authenticated;  // Assuming backend returns {authenticated: true/false}
  } catch (error) {
    console.error("Error during Google authentication check:", error.response ? error.response.data : error.message);
    return false;
  }
};
