import React, { useState } from 'react';
import '../styles/TrendsTable.css';
import { API_URL } from '../config';

const TrendsTable = ({ data }) => {
  // Check if data contains the 'trends' property and use it, otherwise use data directly
  const trendsData = data?.trends || [];
  const [chatOpen, setChatOpen] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const getCsrfToken = () => {
  const cookieValue = document.cookie
    .split('; ')
    .find(row => row.startsWith('csrftoken='))
    ?.split('=')[1];
  return cookieValue || '';
};


const handleSendMessage = async () => {
  if (!userInput.trim()) return;

  // Add the user's message to the chat
  const updatedMessages = [...chatMessages, { role: 'user', content: userInput }];
  setChatMessages(updatedMessages);

  // Send the user's message to the chat API
  setIsLoading(true);
  try {
    const response = await fetch(`${API_URL}/chat/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': getCsrfToken(), // Add CSRF token to the headers
      },
      credentials: 'include', // Ensure cookies are sent with the request
      body: JSON.stringify({ message: userInput }),
    });

    const data = await response.json();
    if (data.message) {
      setChatMessages([...updatedMessages, { role: 'assistant', content: data.message }]);
    }
  } catch (error) {
    setChatMessages([...updatedMessages, { role: 'assistant', content: 'Error fetching chat response.' }]);
  } finally {
    setIsLoading(false);
  }

  // Clear the input
  setUserInput('');
};


  const handleChatClose = () => {
    setChatOpen(false);
    setChatMessages([]);
  };

  return (
    <div className="trends-table-container">
      <table className="trends-table">
        <thead>
          <tr>
            <th>Trend</th>
            <th>Search Volume</th>
            <th>Started</th>
          </tr>
        </thead>
        <tbody>
          {trendsData.map((row, index) => (
            <tr key={index}>
              <td>{row.trend || 'N/A'}</td>
              <td>{row.search_volume || 'N/A'}</td>
              <td>{row.started || 'N/A'}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {chatOpen && (
        <div className="chat-container">
          <div className="chat-header">
            <h3>Chat</h3>
            <button onClick={handleChatClose}>Close</button>
          </div>
          <div className="chat-messages">
            {chatMessages.map((msg, index) => (
              <div key={index} className={`chat-message ${msg.role}`}>
                {msg.content}
              </div>
            ))}
            {isLoading && <div className="loading">Loading...</div>}
          </div>
          <div className="chat-input">
            <input
              type="text"
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              placeholder="Type your message..."
            />
            <button onClick={handleSendMessage}>Send</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TrendsTable;
