import React, { useState } from 'react';
import '../styles/CreateAd.css'; // Ensure you have styles for loading dots in your CSS
const CreateImageAI = ({ onResponse, onLoading, onSetRequestCount }) => {
    const [formData, setFormData] = useState({
        description: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formData.description) {
            console.error('Description is required.');
            return;
        }

        onLoading(true);
        try {
            await onResponse(formData);
        } catch (error) {
            console.error('Error submitting form:', error);
        } finally {
            onLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <label>Description</label>
                <textarea
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    placeholder="Write your description here..."
                    required
                />
            </div>
            <button type="submit">Generate</button>
        </form>
    );
};

export default CreateImageAI;
