import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/CreateAd.css'; // Ensure you have styles for loading dots in your CSS
import { API_URL } from '../config';
import KeywordsIdeas from "./KeywordsIdeas";
import KeywordsSuggestions from "./KeywordsSuggestions";
import CompetitorsKeywords from "./CompetitorsKeywords";
const CreateEmail = ({ onResponse, onLoading, onSetRequestCount, onKeywordsFetched }) => {
    const [formData, setFormData] = useState({
        email: '',
        language: '',
        brand_voice: '',
        emotion: '',
        tone: '',
        target_audience: '',
        formula: '',
        creativity: '',
        use_emoji: false,
        use_hashtags: false,
        prompt: '',
        call_to_action: '',
        keywords: '',
        models: [],
    });

    const [csrfToken, setCsrfToken] = useState('');
    const [emailTypeChoices, setEmailTypeChoices] = useState([]);
    const [languageTypeChoices, setLanguageTypeChoices] = useState([]);
    const [brandTypeChoices, setBrandTypeChoices] = useState([]);
    const [emotionTypeChoices, setEmotionTypeChoices] = useState([]);
    const [toneTypeChoices, setToneTypeChoices] = useState([]);
    const [targetTypeChoices, setTargetTypeChoices] = useState([]);
    const [formulaTypeChoices, setFormulaTypeChoices] = useState([]);
    const [creativityTypeChoices, setCreativityTypeChoices] = useState([]);
    const [requestCount, setRequestCount] = useState(1);
    const [modelChoices, setModelChoices] = useState([]);
    // State to manage collapsible sections
    const [isAudienceMessagingOpen, setIsAudienceMessagingOpen] = useState(false);
    const [isContentStrategyOpen, setIsContentStrategyOpen] = useState(false);
    const [isPreferencesOpen, setIsPreferencesOpen] = useState(false);

    useEffect(() => {
        const fetchCsrfToken = async () => {
            try {
                const response = await axios.get(`${API_URL}/csrf-token/`);
                setCsrfToken(response.data.csrfToken); // Update the state with the token
            } catch (error) {
                console.error('Error fetching CSRF token:', error);
            }
        };

        fetchCsrfToken();
    }, []);

    // Fetch options for select fields
    useEffect(() => {
        const fetchChoices = async (endpoint, setState) => {
            try {
                // Retrieve the token from localStorage or any other secure storage
                const token = localStorage.getItem('access_token');

                const response = await axios.get(`${API_URL}/marketing_content/api/${endpoint}/`, {
                    headers: {
                        'Authorization': `Bearer ${token}` // Add Authorization header
                    }
                });
                setState(response.data);
            } catch (error) {
                console.error(`Error fetching ${endpoint}:`, error);
            }
        };

        fetchChoices('email-types', setEmailTypeChoices);
        fetchChoices('language-types', setLanguageTypeChoices);
        fetchChoices('brand-types', setBrandTypeChoices);
        fetchChoices('emotion-types', setEmotionTypeChoices);
        fetchChoices('tone-types', setToneTypeChoices);
        fetchChoices('target-types', setTargetTypeChoices);
        fetchChoices('formula-types', setFormulaTypeChoices);
        fetchChoices('creativity-types', setCreativityTypeChoices);
        fetchChoices('metrics-choices', setModelChoices);
    }, []);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };
    const handleKeywordsFetch = (data) => {
  try {
    console.log('Fetched data received in CreateAd:', data);
    const fetchedKeywords = data.keywordsData.ideas; // Keep full keyword details
    console.log('Processed keywords:', fetchedKeywords);

    // Pass the fetched data to ProtectedApp
    if (typeof onKeywordsFetched === 'function') {
      onKeywordsFetched(fetchedKeywords); // Pass the full ideas array
    }
  } catch (error) {
    console.error('Error processing fetched keywords:', error);
  }
};

const handleKeywordsSuggestionsFetch = (data) => {
  try {
    console.log('Fetched data received in CreateAd:', data);
    const fetchedKeywords = data.suggestionsData.suggestions; // Keep full keyword details
    console.log('Processed keywords:', fetchedKeywords);

    // Pass the fetched data to ProtectedApp
    if (typeof onKeywordsFetched === 'function') {
      onKeywordsFetched(fetchedKeywords); // Pass the full ideas array
    }
  } catch (error) {
    console.error('Error processing fetched keywords:', error);
  }
};

const handleCompetitorsKeywordsFetch = (data) => {
  try {
    console.log('Fetched data received in CreateAd:', data);
    const fetchedKeywords = data.competitorsData.competitors_keywords; // Keep full keyword details
    console.log('Processed keywords:', fetchedKeywords);

    // Pass the fetched data to ProtectedApp
    if (typeof onKeywordsFetched === 'function') {
      onKeywordsFetched(fetchedKeywords); // Pass the full ideas array
    }
  } catch (error) {
    console.error('Error processing fetched keywords:', error);
  }
};
    const handleModelChange = (e) => {
        const { options } = e.target;
        const selectedModels = Array.from(options)
            .filter(option => option.selected)
            .map(option => option.value);
        setFormData({ ...formData, models: selectedModels });
    };

    const handleRequestCountChange = (e) => {
        const value = parseInt(e.target.value, 10);
        setRequestCount(value);
        if (typeof onSetRequestCount === 'function') {
            onSetRequestCount(value);
        } else {
            console.warn('onSetRequestCount is not defined or is not a function');
        }
    };

    const validateFormData = () => {
        const requiredFields = [];
        for (let field of requiredFields) {
            if (!formData[field]) {
                console.error(`Error: ${field} is required.`);
                return false;
            }
        }
        return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateFormData()) {
            console.error('Please fill in all required fields.');
            return;
        }
        onLoading(true);
        onResponse(formData);
    };

    return (
        <form onSubmit={handleSubmit}>
            {/* Prompt Field */}
            <div>
                <label>Prompt</label>
                <textarea
                    name="prompt"
                    value={formData.prompt}
                    onChange={handleChange}
                    placeholder="Write your instructions here..."
                    required
                />
            </div>

            <div>
                <label>Email Type</label>
                <select
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                >
                    <option value="" disabled>Select Email Type</option>
                    {emailTypeChoices.map((choice) => (
                        <option key={choice.value} value={choice.value}>
                            {choice.label}
                        </option>
                    ))}
                </select>
            </div>

            <div>
                <label>Language</label>
                <select
                    name="language"
                    value={formData.language}
                    onChange={handleChange}
                >
                    <option value="" disabled>Select Language</option>
                    {languageTypeChoices.map((choice) => (
                        <option key={choice.value} value={choice.value}>
                            {choice.label}
                        </option>
                    ))}
                </select>
            </div>

            <div>
                <label>Select Models</label>
                <select
                    name="models"
                    multiple
                    value={formData.models}
                    onChange={handleModelChange}
                >
                    {modelChoices.map((choice) => (
                        <option key={choice.value} value={choice.value}>
                            {choice.label}
                        </option>
                    ))}
                </select>
            </div>

            {/* Audience & Messaging Section */}
            <div className="section">
                <div className="section-header" onClick={() => setIsAudienceMessagingOpen(!isAudienceMessagingOpen)}>
                    Audience & Messaging <span>{isAudienceMessagingOpen ? '-' : '+'}</span>
                </div>
                {isAudienceMessagingOpen && (
                    <div className="section-content">
                        <div>
                            <label>Brand Voice</label>
                            <select
                                name="brand_voice"
                                value={formData.brand_voice}
                                onChange={handleChange}
                            >
                                <option value="" disabled>Select Brand Voice</option>
                                {brandTypeChoices.map((choice) => (
                                    <option key={choice.value} value={choice.value}>
                                        {choice.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label>Target Audience</label>
                            <select
                                name="target_audience"
                                value={formData.target_audience}
                                onChange={handleChange}
                            >
                                <option value="" disabled>Select Target Audience</option>
                                {targetTypeChoices.map((choice) => (
                                    <option key={choice.value} value={choice.value}>
                                        {choice.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label>Emotion</label>
                            <select
                                name="emotion"
                                value={formData.emotion}
                                onChange={handleChange}
                            >
                                <option value="" disabled>Select Emotion</option>
                                {emotionTypeChoices.map((choice) => (
                                    <option key={choice.value} value={choice.value}>
                                        {choice.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label>Tone</label>
                            <select
                                name="tone"
                                value={formData.tone}
                                onChange={handleChange}
                            >
                                <option value="" disabled>Select Tone</option>
                                {toneTypeChoices.map((choice) => (
                                    <option key={choice.value} value={choice.value}>
                                        {choice.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                )}
            </div>

            {/* Content Strategy Section */}
            <div className="section">
                <div className="section-header" onClick={() => setIsContentStrategyOpen(!isContentStrategyOpen)}>
                    Content Strategy <span>{isContentStrategyOpen ? '-' : '+'}</span>
                </div>
                {isContentStrategyOpen && (
                    <div className="section-content">
                        <div>
                            <label>Formula</label>
                            <select
                                name="formula"
                                value={formData.formula}
                                onChange={handleChange}
                            >
                                <option value="" disabled>Select Formula</option>
                                {formulaTypeChoices.map((choice) => (
                                    <option key={choice.value} value={choice.value}>
                                        {choice.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label>Call to Action</label>
                            <input
                                name="call_to_action"
                                value={formData.call_to_action}
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <label>Keywords (optional)</label>
                            <input
                                name="keywords"
                                value={formData.keywords}
                                onChange={handleChange}
                            />
                            <KeywordsIdeas onFetchDataComplete={handleKeywordsFetch} csrfToken={csrfToken} />
                            <KeywordsSuggestions onFetchDataComplete={handleKeywordsSuggestionsFetch} csrfToken={csrfToken} />
                            <CompetitorsKeywords onFetchDataComplete={handleCompetitorsKeywordsFetch} csrfToken={csrfToken} />
                        </div>
                    </div>
                )}
            </div>

            {/* Preferences Section */}
            <div className="section">
                <div className="section-header" onClick={() => setIsPreferencesOpen(!isPreferencesOpen)}>
                    Preferences <span>{isPreferencesOpen ? '-' : '+'}</span>
                </div>
                {isPreferencesOpen && (
                    <div className="section-content">
                        <div>
                            <label>Creativity</label>
                            <select
                                name="creativity"
                                value={formData.creativity}
                                onChange={handleChange}
                            >
                                <option value="" disabled>Select Creativity</option>
                                {creativityTypeChoices.map((choice) => (
                                    <option key={choice.value} value={choice.value}>
                                        {choice.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label>Use Emoji</label>
                            <input
                                type="checkbox"
                                name="use_emoji"
                                checked={formData.use_emoji}
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <label>Use Hashtags</label>
                            <input
                                type="checkbox"
                                name="use_hashtags"
                                checked={formData.use_hashtags}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                )}
            </div>

            {/* Number of Requests Field */}
            <div>
                <label>Number of Requests</label>
                <input
                    type="number"
                    name="request_count"
                    min="1"
                    value={requestCount}
                    onChange={handleRequestCountChange}
                />
            </div>

            <button type="submit">Generate</button>
        </form>
    );
};

export default CreateEmail;
