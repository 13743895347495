import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../styles/CreateSearch.css';
import { API_URL } from '../config';

const CreateSearch = ({ onFetchDataComplete, csrfToken }) => {
  const [sites, setSites] = useState([]);
  const [selectedSite, setSelectedSite] = useState('');
  const [dateRange, setDateRange] = useState('custom');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchSearchConsoleSites = async () => {
      try {
        const response = await axios.get(`${API_URL}/fetch_search_console/`, {
          withCredentials: true,
        });
        const siteUrls = response.data.map(site => ({
          id: site.siteUrl,
          displayName: site.siteUrl,
        }));
        setSites(siteUrls);
      } catch (error) {
        console.error('Error fetching search console sites:', error);
        setError('Please log in to access Google Search Console data.');
      }
    };

    fetchSearchConsoleSites();
  }, []);

  const handleLoginRequest = () => {
    window.location.href = `${API_URL}/login`;
  };

  const handleDateRangeChange = (range) => {
    setDateRange(range);
    const today = new Date();

    if (range === 'last7days') {
      const last7Days = new Date();
      last7Days.setDate(today.getDate() - 7);
      setStartDate(last7Days.toISOString().split('T')[0]);
      setEndDate(today.toISOString().split('T')[0]);
    } else if (range === 'last28days') {
      const last28Days = new Date();
      last28Days.setDate(today.getDate() - 28);
      setStartDate(last28Days.toISOString().split('T')[0]);
      setEndDate(today.toISOString().split('T')[0]);
    } else if (range === 'last6months') {
      const last6Months = new Date();
      last6Months.setMonth(today.getMonth() - 6);
      setStartDate(last6Months.toISOString().split('T')[0]);
      setEndDate(today.toISOString().split('T')[0]);
    } else {
      setStartDate('');
      setEndDate('');
    }
  };

  const handleFetchData = async () => {
    if (!selectedSite || !startDate || !endDate) {
      setError('Please select a site and enter start and end dates.');
      return;
    }

    setLoading(true);

    const payload = {
      site_url: selectedSite,
      start_date: startDate,
      end_date: endDate,
    };

    try {
      const queriesResponse = await axios.post(
        `${API_URL}/top-queries/`,
        payload,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
          },
        }
      );

      const pagesResponse = await axios.post(
        `${API_URL}/top-pages/`,
        payload,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
          },
        }
      );

      const countriesResponse = await axios.post(
        `${API_URL}/top-countries/`,
        payload,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
          },
        }
      );

      const devicesResponse = await axios.post(
        `${API_URL}/top-devices/`,
        payload,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
          },
        }
      );

      setError(null);
      onFetchDataComplete({
        queriesData: queriesResponse.data,
        pagesData: pagesResponse.data,
        countriesData: countriesResponse.data,
        devicesData: devicesResponse.data,
      });
    } catch (error) {
      console.error('Error fetching search console data:', error);
      setError('Failed to fetch search console data. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="search-console-container">
      <h1 className="search-console-title">Google Search Console</h1>
      {error && <p className="search-console-error">{error}</p>}
      {!sites.length ? (
        <>
          {error ? (
            <button className="search-console-button" onClick={handleLoginRequest}>
              Login with Google
            </button>
          ) : (
            <p className="search-console-loading">Loading sites...</p>
          )}
        </>
      ) : (
        <div className="search-console-form">
          <label className="search-console-label" htmlFor="sites">
            Select a Google Search Console Site:
          </label>
          <select
            className="search-console-select"
            id="sites"
            value={selectedSite}
            onChange={(e) => setSelectedSite(e.target.value)}
          >
            <option value="">-- Select a Site --</option>
            {sites.map((site) => (
              <option key={site.id} value={site.id}>
                {site.displayName}
              </option>
            ))}
          </select>

          <div className="search-console-date-range">
            <label className="search-console-label" htmlFor="date-range">Date Range:</label>
            <select
              className="search-console-select"
              id="date-range"
              value={dateRange}
              onChange={(e) => handleDateRangeChange(e.target.value)}
            >
              <option value="custom">Custom</option>
              <option value="last7days">Last 7 Days</option>
              <option value="last28days">Last 28 Days</option>
              <option value="last6months">Last 6 Months</option>
            </select>
          </div>

          {dateRange === 'custom' && (
            <>
              <div className="search-console-date-input">
                <label className="search-console-label" htmlFor="start-date">Start Date:</label>
                <input
                  type="date"
                  id="start-date"
                  className="search-console-date-picker"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>

              <div className="search-console-date-input">
                <label className="search-console-label" htmlFor="end-date">End Date:</label>
                <input
                  type="date"
                  id="end-date"
                  className="search-console-date-picker"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </>
          )}

          <button
            className="search-console-button"
            onClick={handleFetchData}
            disabled={loading}
          >
            {loading ? 'Fetching...' : 'Fetch Search Console Data'}
          </button>

          {loading && <div className="search-console-spinner"></div>}
        </div>
      )}
    </div>
  );
};

export default CreateSearch;
