import React from 'react';
import '../styles/CountryTrafficTable.css';

const CountryTrafficTable = ({ data }) => {
  return (
    <div className="country-traffic-table-container">
      <table className="country-traffic-table">
        <thead>
          <tr>
            <th>Country</th>
            <th>Clicks</th>
            <th>Impressions</th>
            <th>CTR</th>
            <th>Position</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td>{row.country.toUpperCase()}</td>
              <td>{row.clicks}</td>
              <td>{row.impressions}</td>
              <td>{row.ctr}</td>
              <td>{row.position.toFixed(1)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CountryTrafficTable;
