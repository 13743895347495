import React, { useState } from "react";
import axios from "axios";
import { login } from "../auth";
import { Link } from "react-router-dom";
import google_image from "../assets/google.png";
import wizard_image from "../assets/wizard.png";
import login_content from "../assets/login_content.png";
import { API_URL } from "../config";
import "../styles/Login.css";

function Login() {
  const [credentials, setCredentials] = useState({ username: "", password: "" });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_URL}/users/login/`, credentials);
      if (response.data.access && response.data.refresh) {
        login(response.data.access, response.data.refresh);
        window.location.href = "/protected";
      } else {
        alert("Login failed: No tokens received.");
      }
    } catch (error) {
      alert("Login failed. Please check your credentials.");
    }
  };

  const handleGoogleLogin = () => {
    window.location.href = `${API_URL}/accounts/google/login/`;
  };

  return (
    <div className="login-page">
      <div className="login-left">
        <div className="login-left-content">
          <img
            src={login_content}
            alt="Brand Wizard Illustration"
            className="login-left-image"
          />
          <h1>Brand Wizard</h1>
          <p>
            Your AI marketing magic: strategies & tools, crafted from your data
            for your audience.
          </p>
        </div>
      </div>
      <div className="login-right">
        <form className="login-form" onSubmit={handleSubmit}>
          <div className="login-header">
            <img
              src={wizard_image}
              alt="Wizard Icon"
              className="login-header-icon"
            />
            <h2>Welcome back</h2>
            <p>Login below to get back your marketing superpowers</p>
          </div>
          <button
            className="google-login-button"
            type="button"
            onClick={handleGoogleLogin}
          >
            <img src={google_image} alt="Google Logo" className="google-logo" />
            Start with Google
          </button>
          <div className="divider">Or continue with your email</div>
          <div className="input-group">
            <input
              type="text"
              name="username"
              className="login-input"
              placeholder="Type your email"
              onChange={handleChange}
              required
            />
          </div>
          <div className="input-group">
            <input
              type="password"
              name="password"
              className="login-input"
              placeholder="Type your Password"
              onChange={handleChange}
              required
            />
          </div>
          <Link to="/forgot-password" className="forgot-password-link">
            Forgot your password?
          </Link>
          <button className="login-button" type="submit">
            Login
          </button>
          <div className="signup-link">
            <p>
              Don't have an account? <Link to="/signup">Sign up</Link>
            </p>
          </div>
          <div className="contact-links">
            <p>
              Need help? Contact us at{" "}
              <a href="mailto:team@brandwizard.ai">team@brandwizard.ai</a>
            </p>
            <p>
              Read our <Link to="/privacy-policy">Privacy Policy</Link> and{" "}
              <Link to="/terms-of-service">Terms of Service</Link>.
            </p>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
